import React, { useEffect, useState } from 'react';
import { useSeason } from './SeasonContext';
import HeatmapComponent from './WardHeatmap';
import WardMapComponent from './WardMap';
import { useParams } from 'react-router-dom';
import LoadingComponent from './base/LoadingComponent';
import axiosInstance from './backend/axiosInstance';

const TeamHeatmapComponent = () => {
  const { currentSeasonTime } = useSeason();
  const { teamName, gameType } = useParams();

  document.title = "Heatmap" + teamName;

  const [wardData, setWardData] = useState([]);
  const [filteredWardData, setFilteredWardData] = useState([]);
  const [playerOption, setPlayerOption] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]); // Tracks selected players

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    let apiUrl;
    if (gameType === 'Officials') {
      apiUrl = 'playerwards/?team=' + teamName + '&start=' + start + "&end=" + end;
    } else {
      apiUrl = 'playerwards_official/?team=' + teamName;
    }
    axiosInstance.get(apiUrl)
      .then((response) => {
        setWardData(response.data);
        setPlayerOption([...new Set(response.data.map((ward) => ward.summonerName))]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [currentSeasonTime, teamName]);

  // Filter ward data based on selected players
  useEffect(() => {
    if (selectedPlayers.length === 0) {
      setFilteredWardData(wardData);
    } else {
      setFilteredWardData(wardData.filter(ward => selectedPlayers.includes(ward.summonerName)));
    }
  }, [wardData, selectedPlayers]);

  // Handle checkbox selection
  const handlePlayerSelection = (player) => {
    setSelectedPlayers((prevSelected) =>
      prevSelected.includes(player)
        ? prevSelected.filter((p) => p !== player) // Remove player if already selected
        : [...prevSelected, player] // Add player if not selected
    );
  };

  return (
    <div>
      <div>
        {wardData ? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <br></br>
              <h1>WARDS HEATMAP</h1>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {playerOption.map((player, index) => (
                    <div key={index} style={{ color: 'white', textAlign: "left", display: 'flex' }}>
                      {player}
                      <input
                        type="checkbox"
                        checked={selectedPlayers.includes(player)}
                        onChange={() => handlePlayerSelection(player)}
                      />
                    </div>
                  ))}
                </div>
                <HeatmapComponent wardData={filteredWardData} />
                <div>

                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>

      <div>
        {wardData ? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <br></br>
              <h1>WARDS MAP</h1>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {playerOption.map((player, index) => (
                    <div key={index} style={{ color: 'white', textAlign: "left", display: 'flex' }}>
                      {player}
                      <input
                        type="checkbox"
                        checked={selectedPlayers.includes(player)}
                        onChange={() => handlePlayerSelection(player)}
                      />
                    </div>
                  ))}
                </div>
                <WardMapComponent wardData={filteredWardData} />
                <div>

                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
};

export default TeamHeatmapComponent;
