import React, { useState, useEffect } from 'react';
import { championData, championPhotos } from './ChampionsIcon';
import './TierlistForm.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './backend/axiosInstance';
const ChampionRoleComponent = () => {
  const [columns, setColumns] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});

  useEffect(() => {
    axiosInstance.get('champion_roles/')
      .then(response => {
        const { columns, data } = response.data;

        setColumns(columns);

        const checkboxState = data.reduce((acc, item) => {
          const { championId, className } = item;
          if (!acc[championId]) {
            acc[championId] = {};
          }
          acc[championId][className] = true;
          return acc;
        }, {});
        setCheckboxes(checkboxState);
      })
      .catch(error => {
        console.error("Error fetching champion classes", error);
      });
  }, []);

  const handleCheckboxChange = (championId, className) => {
    setCheckboxes(prevState => ({
      ...prevState,
      [championId]: {
        ...prevState[championId],
        [className]: !prevState[championId]?.[className]
      }
    }));
  };

  const addColumn = () => {
    const newColumn = prompt("Enter class name:");
    if (newColumn && !columns.includes(newColumn)) {
      setColumns([...columns, newColumn]);

      setCheckboxes(prevState => {
        const updatedState = { ...prevState };
        Object.keys(championData).forEach(championId => {
          if (!updatedState[championId]) {
            updatedState[championId] = {};
          }
          updatedState[championId][newColumn] = false;
        });
        return updatedState;
      });
    }
  };

  const handleSave = () => {
    const dataToSave = [];
    Object.entries(checkboxes).forEach(([championId, classStates]) => {
      Object.entries(classStates).forEach(([className, isChecked]) => {
        if (isChecked) {
          dataToSave.push({ championId, className });
        }
      });
    });

    const payload = {
      columns: columns,
      data: dataToSave,
    };

    axiosInstance.post('champion_roles/', payload)
      .then(() => toast.success("Data saved successfully"))
      .catch(error => {
        console.error("Error saving data", error);
        toast.error("Error saving data");
      });
  };

  // Sorting champions by name for rendering
  const sortedChampions = Object.entries(championData)
    .sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB));

    const handleTierlistClick= () => {
      window.location.href = `/Tierlist`;
    };

  return (
    <div style={{ padding: "15px" }}>
      <h1 style={{ color: 'white' }}>Champion Role</h1>
      <button onClick={() => handleTierlistClick()}>Tierlist</button>
      <table>
        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'gray', zIndex: 1 }}>
          <tr>
            <th>Champion Name</th>
            {columns.map((col, index) => (
              <th style={{textAlign: 'center'}} key={index}>{col}</th>
            ))}
            <th><button style={{width: "100%"}} onClick={addColumn}>+</button></th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "white" }}>
            {sortedChampions.map(([id, name], index) => (
            <tr key={id} style={{ backgroundColor: index % 2 === 0 ? 'rgb(215 207 182)' : '#fffff', borderBottom: '1px solid #ddd' }}>
              <td style={{ padding: '12px', border: '1px solid #ddd' }}>
                <img className='small-image' src={championPhotos[name]} alt={name} />{name}
              </td>
              {columns.map((col, index) => (
                <td style={{textAlign: "center"}}key={index}>
                  <input
                    type="checkbox"
                    checked={checkboxes[id]?.[col] || false}
                    onChange={() => handleCheckboxChange(id, col)}
                  />
                </td>
              ))}
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
      <button style={{width: "100%"}}onClick={handleSave}>Save</button>
      <ToastContainer />
    </div>
  );
};

export default ChampionRoleComponent;
