import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { championPhotos, championData } from './ChampionsIcon';
import roleIcon from './Role';
import { TeamIcons } from './TeamIcons';
import axiosInstance from './backend/axiosInstance';
const EditScrimComponent = () => {
    const { gameId } = useParams();
    const [matchData, setMatchData] = useState();
    const [blueChampions, setBlueChampions] = useState([]);
    const [redChampions, setRedChampions] = useState([]);
    const [editedChampions, setEditedChampions] = useState({});
    const [editedRoles, setEditedRoles] = useState({});
    const [blueBC, setBlueBC] = useState({});
    const [redBC, setRedBC] = useState({});
    const [roleSelectionOrder, setRoleSelectionOrder] = useState([]);

    const order = [
      "B1R", "R1R", "R2R", "B2R", "B3R", "R3R", "R4R", "B4R", "B5R", "R5R"
    ];

    useEffect(() => {
      axiosInstance.get('parsedscrims/?gameId=' + gameId)
        .then((response) => {
          setMatchData(response.data);
          console.log(response.data);
          document.title = "EDIT: " + response.data[0]["team1"] + "-" + response.data[0]["team2"];
        })
        .catch((error) => {
          console.error('Error fetching match data:', error);
        });
    }, [gameId]);

    useEffect(() => {
      if (matchData) {
        const blue = [
          "BB1", "BB2", "BB3", "BP1", "BP2", "BP3", "BB4", "BB5", "BP4", "BP5"
        ];
        const red = [
          "RB1", "RB2", "RB3", "RP1", "RP2", "RP3", "RB4", "RB5", "RP4", "RP5"
        ];
        const blueRoles = [
          "B1R", "B2R", "B3R", "B4R", "B5R"
        ];
        const redRoles = [
          "R1R", "R2R", "R3R", "R4R", "R5R"
        ];

        const blueBC = [
          "B1BC", "B2BC", "B3BC", "B4BC", "B5BC"
        ];
        const redBC = [
          "R1BC", "R2BC", "R3BC", "R4BC", "R5BC"
        ];

        setBlueChampions(blue);
        setRedChampions(red);

        setEditedChampions(
          blue.concat(red).reduce((acc, key) => {
            acc[key] = matchData[0][key];  // Allow for null values
            return acc;
          }, {})
        );

        setEditedRoles(
          blueRoles.concat(redRoles).reduce((acc, key) => {
            acc[key] = matchData[0][key];  // Allow for null values
            return acc;
          }, {})
        );

        setBlueBC(
          blueBC.reduce((acc, key) => {
            acc[key] = matchData[0][key];  // Allow for null values
            return acc;
          }, {})
        );

        setRedBC(
          redBC.reduce((acc, key) => {
            acc[key] = matchData[0][key];  // Allow for null values
            return acc;
          }, {})
        );
      }
    }, [matchData]);

    const handleChampionChange = (key, value) => {
      setEditedChampions((prev) => ({
        ...prev,
        [key]: parseInt(value, 10),  // Allow for null values
      }));
    };

    const handleRoleChange = (key, value) => {
      setEditedRoles((prev) => ({
        ...prev,
        [key]: parseInt(value, 10),  // Allow for null values
      }));

      setRoleSelectionOrder((prevOrder) => {
        const newOrder = [...prevOrder, key];
        return newOrder;
      });
    };

    const updateBlindCounter = (blueRoles, redRoles, callback) => {
        const newBlueBC = { ...blueBC };
        const newRedBC = { ...redBC };

        newBlueBC.B1BC = 'B';
        newRedBC.R5BC = 'C';

        if (blueRoles.length > 0 && redRoles.length > 0) {
          if (blueRoles[0] === redRoles[0]) newRedBC.R1BC = 'C';
          else newRedBC.R1BC = 'B';
        }
        if (blueRoles.length > 1 && redRoles.length > 1) {
          if (blueRoles[0] === redRoles[1]) newRedBC.R2BC = 'C';
          else newRedBC.R2BC = 'B';
          if (blueRoles[1] === redRoles[0] || blueRoles[1] === redRoles[1]) newBlueBC.B2BC = 'C';
          else newBlueBC.B2BC = 'B';
        }
        if (blueRoles.length > 2 && redRoles.length > 2) {
          if (blueRoles[2] === redRoles[0] || blueRoles[2] === redRoles[1]) newBlueBC.B3BC = 'C';
          else newBlueBC.B3BC = 'B';
        }
        if (redRoles.length > 2 && blueRoles.length > 2) {
          if (redRoles[2] === blueRoles[0] || redRoles[2] === blueRoles[1] || redRoles[2] === blueRoles[2]) newRedBC.R3BC = 'C';
          else newRedBC.R3BC = 'B';
        }
        if (redRoles.length > 3 && blueRoles.length > 3) {
          if (redRoles[3] === blueRoles[0] || redRoles[3] === blueRoles[1] || redRoles[3] === blueRoles[2]) newRedBC.R4BC = 'C';
          else newRedBC.R4BC = 'B';
          if (blueRoles[3] === redRoles[0] || blueRoles[3] === redRoles[1] || blueRoles[3] === redRoles[2] || blueRoles[3] === redRoles[3]) newBlueBC.B4BC = 'C';
          else newBlueBC.B4BC = 'B';
        }
        if (blueRoles.length > 4 && redRoles.length > 4) {
          if (blueRoles[4] === redRoles[0] || blueRoles[4] === redRoles[1] || blueRoles[4] === redRoles[2] || blueRoles[4] === redRoles[3]) newBlueBC.B5BC = 'C';
          else newBlueBC.B5BC = 'B';
        }

        setBlueBC(newBlueBC);
        setRedBC(newRedBC);

        if (callback) {
          callback(newBlueBC, newRedBC);
        }
      };

    const checkUnique = (array) => {
      const nonNullArray = array.filter(item => item !== null);
      const uniqueValues = new Set(nonNullArray);
      return uniqueValues.size === nonNullArray.length;
    };

    const handleSave = () => {
      const blueRolesArray = Object.keys(editedRoles).filter(key => key.startsWith('B')).map(key => editedRoles[key]);
      const redRolesArray = Object.keys(editedRoles).filter(key => key.startsWith('R')).map(key => editedRoles[key]);

      const blueChampionsArray = Object.keys(editedChampions).filter(key => key.startsWith('B')).map(key => editedChampions[key]);
      const redChampionsArray = Object.keys(editedChampions).filter(key => key.startsWith('R')).map(key => editedChampions[key]);

      const allChampionsArray = blueChampionsArray.concat(redChampionsArray);

      if (!checkUnique(blueRolesArray)) {
        alert("Blue side roles must be unique");
        return;
      }

      if (!checkUnique(redRolesArray)) {
        alert("Red side roles must be unique");
        return;
      }

      if (!checkUnique(allChampionsArray)) {
        alert("Non-null champions must be unique");
        return;
      }


      updateBlindCounter(blueRolesArray, redRolesArray, (newBlueBC, newRedBC) => {
        const updatedData = {
          ...matchData[0],
          ...editedChampions,
          ...editedRoles,
          ...newBlueBC,
          ...newRedBC,
        };
        console.log(updatedData);

        axiosInstance.put(`parsedscrims/?gameId=${gameId}`, updatedData)
          .then((response) => {
            console.log('Data saved successfully:', response.data);
          })
          .catch((error) => {
            console.error('Error saving data:', error);
          });
      });

      // Add the axios post request here to save the changes to the server if needed
    };

    const handleScrimClick = (teamName) => {
        window.location.href = `/scrim/${gameId}`;
      };

  const handleRunScriptTeam1Click = () => {
    const scrimId = gameId; // assuming gameId is defined and has the correct value
    axiosInstance.post('changeWinnerTeam1/', { scrimId })
      .then(response => {
        console.log(response.data);
        alert('Script ran successfully: ' + response.data.message);
      })
      .catch(error => {
        console.error('Error running script:', error);
        alert('Failed to run script');
      });
  };

  const handleRunScriptTeam2Click = () => {
    const scrimId = gameId; // assuming gameId is defined and has the correct value
    axiosInstance.post('changeWinnerTeam2/', { scrimId })
      .then(response => {
        console.log(response.data);
        alert('Script ran successfully: ' + response.data.message);
      })
      .catch(error => {
        console.error('Error running script:', error);
        alert('Failed to run script');
      });
  };


  return (
    <>

      {matchData ? (
        <>
        <div style={{display: 'flex', flexDirection: 'column', height: "100vh"}}>
        <div style={{width: "100%"}}>
            <button onClick={handleScrimClick}>GO BACK</button>
        </div>
        <div style={{width: "100%"}}>
            <button onClick={handleRunScriptTeam1Click}>Change winner to team1 (blue)</button>
            <button onClick={handleRunScriptTeam2Click}>Change winner to team2 (red)</button>
        </div>
          <div className='match-draft-container'>
            <table>
              <thead style={{ textAlign: 'center' }}>
                <tr>
                  <th className="default-cell-img" style={{ backgroundColor: matchData[0].winner === 100 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)' }}>
                    {matchData[0].winner === 100 ? 1 : 0}
                  </th>
                  <th className="teamName">
                    <img src={TeamIcons[matchData[0].team1]} alt='ni ma ikonki' className="small-image" />
                    {matchData[0].team1}
                  </th>
                  <th colSpan={2} className='default-doublecell-img'>
                    {Math.floor(matchData[0].gameDuration / 60)}:{String(matchData[0].gameDuration % 60).padStart(2, '0')}
                  </th>
                  <th className="teamName">
                    {matchData[0].team2}
                    <img src={TeamIcons[matchData[0].team2]} alt='ni ma ikonki' className="small-image" />
                  </th>
                  <th className="default-cell-img" style={{ backgroundColor: matchData[0].winner === 200 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)' }}>
                    {matchData[0].winner === 200 ? 1 : 0}
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...Array(10)].map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className={rowIndex >= 3 && rowIndex <= 5 || rowIndex >= 8 ? "blue-cell-img" : "default-cell-img"}>
                      <img
                        src={championPhotos[championData[editedChampions[blueChampions[rowIndex]]]]}
                        alt={""}
                        className="small-image"
                      />
                    </td>
                    <td
                      style={{ borderBottom: rowIndex === 3 ? '1px solid black' : 'none', borderTop: rowIndex === 4 ? '1px solid black' : 'none' }}
                      colSpan={rowIndex < 3 || (rowIndex >= 6 && rowIndex < 8) ? 2 : 1}
                      className={rowIndex >= 3 && rowIndex <= 5 || rowIndex >= 8 ? "blue-cell" : "default-cell"}
                    >
                      <select
                        value={editedChampions[blueChampions[rowIndex]] || ""}
                        onChange={(e) => handleChampionChange(blueChampions[rowIndex], e.target.value)}
                      >
                        <option value="0">None</option>
                        {Object.keys(championData).map((championKey) => (
                          <option key={championKey} value={championKey}>
                            {championData[championKey]}
                          </option>
                        ))}
                      </select>
                    </td>
                    {rowIndex >= 3 && rowIndex <= 5 || rowIndex >= 8 ? (
                      <>
                        {rowIndex >= 8 ? (
                          <>
                            <td className={"blue-cell-img"}>
                              <select
                                value={editedRoles[`B${rowIndex - 4}R`] || ""}
                                onChange={(e) => handleRoleChange(`B${rowIndex - 4}R`, e.target.value)}
                              >
                                {Object.keys(roleIcon).map((roleKey) => (
                                  <option key={roleKey} value={roleKey}>
                                    {roleKey}
                                  </option>
                                ))}
                              </select>
                              <img src={roleIcon[editedRoles[`B${rowIndex - 4}R`]]} alt={1} className="small-image" />
                            </td>
                            <td className={"red-cell-img"}>
                              <select
                                value={editedRoles[`R${rowIndex - 4}R`] || ""}
                                onChange={(e) => handleRoleChange(`R${rowIndex - 4}R`, e.target.value)}
                              >
                                {Object.keys(roleIcon).map((roleKey) => (
                                  <option key={roleKey} value={roleKey}>
                                    {roleKey}
                                  </option>
                                ))}
                              </select>
                              <img src={roleIcon[editedRoles[`R${rowIndex - 4}R`]]} alt={1} className="small-image" />
                            </td>
                          </>
                        ) : (
                          <>
                            <td className={rowIndex >= 3 && rowIndex <= 5 ? "blue-cell-img" : "default-cell-img"}>
                              <select
                                value={editedRoles[`B${rowIndex - 2}R`] || ""}
                                onChange={(e) => handleRoleChange(`B${rowIndex - 2}R`, e.target.value)}
                              >
                                {Object.keys(roleIcon).map((roleKey) => (
                                  <option key={roleKey} value={roleKey}>
                                    {roleKey}
                                  </option>
                                ))}
                              </select>
                              <img src={roleIcon[editedRoles[`B${rowIndex - 2}R`]]} alt={1} className="small-image" />
                            </td>
                            <td className={rowIndex >= 3 && rowIndex <= 5 ? "red-cell-img" : "default-cell-img"}>
                              <select
                                value={editedRoles[`R${rowIndex - 2}R`] || ""}
                                onChange={(e) => handleRoleChange(`R${rowIndex - 2}R`, e.target.value)}
                              >
                                {Object.keys(roleIcon).map((roleKey) => (
                                  <option key={roleKey} value={roleKey}>
                                    {roleKey}
                                  </option>
                                ))}
                              </select>
                              <img src={roleIcon[editedRoles[`R${rowIndex - 2}R`]]} alt={1} className="small-image" />
                            </td>
                          </>
                        )}
                        <td
                          colSpan={1}
                          style={{ borderBottom: rowIndex === 4 || rowIndex === 8 ? '1px solid black' : 'none', borderTop: rowIndex === 5 || rowIndex === 9 ? '1px solid black' : 'none' }}
                          className={rowIndex >= 3 && rowIndex <= 5 || rowIndex >= 8 ? "red-cell" : "default-cell"}
                        >
                          <select
                            value={editedChampions[redChampions[rowIndex]] || ""}
                            onChange={(e) => handleChampionChange(redChampions[rowIndex], e.target.value)}
                          >
                            <option value="0">None</option>
                            {Object.keys(championData).map((championKey) => (
                              <option key={championKey} value={championKey}>
                                {championData[championKey]}
                              </option>
                            ))}
                          </select>
                        </td>
                      </>
                    ) : (
                      <>
                        <td colSpan={2} className={rowIndex >= 3 && rowIndex <= 5 || rowIndex >= 8 ? "red-cell" : "default-cell"}>
                          <select
                            value={editedChampions[redChampions[rowIndex]] || ""}
                            onChange={(e) => handleChampionChange(redChampions[rowIndex], e.target.value)}
                          >
                            <option value="0">None</option>
                            {Object.keys(championData).map((championKey) => (
                              <option key={championKey} value={championKey}>
                                {championData[championKey]}
                              </option>
                            ))}
                          </select>
                        </td>
                      </>
                    )}
                    <td className={rowIndex >= 3 && rowIndex <= 5 || rowIndex >= 8 ? "red-cell-img" : "default-cell-img"}>
                      <img
                        src={championPhotos[championData[editedChampions[redChampions[rowIndex]]]]}
                        alt={""}
                        className="small-image"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={handleSave}>Save Draft Changes</button>
          </div>
          </div>
        </>
      ) : (
        <>
          XD
        </>
      )}
    </>
  );
};

export default EditScrimComponent;
