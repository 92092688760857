import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import roleIcon from './Role'; // Adjust the path accordingly
import './Player.css';
import { championData, championPhotos } from "./ChampionsIcon";
import 'react-pivottable/pivottable.css';
import { TeamIcons } from './TeamIcons';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';
import { useSeason } from './SeasonContext';
import mapPhotsDictionary from './MapData';
import GIF from 'gif.js';
import axiosInstance from './backend/axiosInstance';
function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
    const teamOrderDict = isBlueTeam ? {
        [firstChampion]: "BP1",
        [secondChampion]: "BP2",
        [thirdChampion]: "BP3",
        [fourthChampion]: "BP4",
        [fifthChampion]: "BP5",
    } : {
        [firstChampion]: "RP1",
        [secondChampion]: "RP2",
        [thirdChampion]: "RP3",
        [fourthChampion]: "RP4",
        [fifthChampion]: "RP5",
    };

    return [0, 1, 2, 3, 4].map(index => {
        const championKey = teamOrderDict[isBlueTeam ? game[`B${index + 1}R`] : game[`R${index + 1}R`]];
        return (
            <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
                <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
                <img
                    src={championPhotos[championData[game[championKey]]]}
                    alt={""}
                    className="small-image"
                />
            </div>
        );
    });
}

const Level1Component = () => {
  const { currentSeasonTime } = useSeason();
  const { teamName } = useParams();

  document.title = "Level 1 " + teamName;

  const [matchData, setMatchData] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [wardData, setWardData] = useState(null); // New state for ward data
  const [playerPosition, setPlayerPosition] = useState(null); // New state for ward data
  const [gifLoading, setGifLoading] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);

  const [inputTime, setInputTime] = useState(45);
  const [inputTime2, setInputTime2] = useState(60);
  const [inputTime3, setInputTime3] = useState(75);

  const [timePoint1, setTimePoint1] = useState('0:30');
  const [timePoint2, setTimePoint2] = useState('1:30');

  const mapWidth = 500;
  const mapHeight = 500;
  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;


  const translateCoordinates = (x, y) => {
    const newX = ((x - minX) / (maxX - minX)) * mapWidth;
    const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
    return { newX, newY };
  };

  const handleGameSelect = (game) => {
    setSelectedGame(game);
    setDropdownOpen(false); // Close dropdown after selection
    fetchData(game.gameId); // Fetch ward data for the selected game
    };

    const fetchData = (gameId) => {
        axiosInstance.get(`playerwards_official/?gameId=${gameId}&short=1`)
            .then(response => {
                setWardData(response.data); // Set ward data
            })
            .catch(error => {
                console.error('Error fetching ward data:', error);
            });
        axiosInstance.get(`playerposition/?gameId=${gameId}&short=1`)
            .then(response => {
                setPlayerPosition(response.data); // Set ward data
            })
            .catch(error => {
                console.error('Error fetching ward data:', error);
            });
    };

    const handleSliderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setCurrentTime(newValue);
        setSliderValue(newValue);
      };

  const captureScreenshot = (table) => {
    const element = document.getElementById(table);

    html2canvas(element)
      .then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'screenshot.png';
        link.href = image;
        link.click();
      })
      .catch(err => {
        console.error('Error capturing screenshot', err);
      });
  };

  const captureFrame = async () => {
    const mapElement = document.querySelector(".map-container"); // Your map container class
    return html2canvas(mapElement, {
        useCORS: true,
        scale: 1,  // To improve GIF quality
    });
};

  const makeGif = async () => {
    const start = parseTime(timePoint1);
    const end = parseTime(timePoint2);

    setGifLoading(true);

    // Initialize the GIF
    const gif = new GIF({
        workers: 2,
        quality: 1,
        workerScript: "/gif.worker.js",
    });

    try {

        // Loop through the time points to capture frames
        for (let time = start; time <= end; time += 1) {
            setCurrentTime(time);  // Simulate the slider time changing

            const canvas = await captureFrame();  // Capture the current frame
            if (canvas) {  // Check if the canvas is valid
                gif.addFrame(canvas, { delay: 1 });  // 100 ms per frame
            } else {
                console.warn(`No valid canvas returned for time: ${time}`);
            }
        }

        gif.on('finished', function (blob) {
            setGifLoading(false);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'map-animation.gif';
            link.click();
        });

        gif.render();
    } catch (error) {
        console.error("Error while creating GIF:", error);
        setGifLoading(false);
    }
};

  const parseTime = (timeStr) => {
    const [minutes, seconds] = timeStr.split(':').map(Number);
    return minutes * 60 + seconds;
  };

  const handleTimeChange = (e, setTime) => {
    const value = e.target.value;
    const validTime = value.match(/^([0-5]?[0-9]):([0-5]?[0-9])$/); // MM:SS format validation
    if (validTime) {
      setTime(value);
    }
  };

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get('parsed_official_games/?start=' + start + "&end=" + end + '&team=' + teamName)
    .then((response) => {
      const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMatchData(sortedMatchData);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }, [currentSeasonTime, teamName]);

  return (
    <div>
        <div className='filters' style={{ textAlign: 'center', marginBottom: '20px', color: "black" }}>
            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '700px',
                    height: '67px',
                    backgroundColor: 'white',
                    position: 'relative',
                }}
            >
                {selectedGame ?  (
                <div style={{
                    border: '1px solid #ccc',
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 100,
                    width: '700px',
                    maxHeight: '400px',
                    overflowY: 'auto'
                }}>
                        <div
                            key={selectedGame.gameId}
                            onClick={() => handleGameSelect(selectedGame)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderBottom: '1px solid #ccc',
                                padding: "10px"
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{width: "120px"}}>
                                    {selectedGame.leagueName} {selectedGame.patch}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {renderChampions(selectedGame, selectedGame.B1R, selectedGame.B2R, selectedGame.B3R, selectedGame.B4R, selectedGame.B5R, true)}
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                        <img className="small-image" src={TeamIcons[selectedGame.team1]} alt={selectedGame.team1} style={{ width: '25px', marginRight: '5px' }} />
                                        {selectedGame.team1} vs {selectedGame.team2}
                                        <img className="small-image" src={TeamIcons[selectedGame.team2]} alt={selectedGame.team2} style={{ width: '25px', marginLeft: '5px' }} />
                                    </div>
                                    {renderChampions(selectedGame, selectedGame.R1R, selectedGame.R2R, selectedGame.R3R, selectedGame.R4R, selectedGame.R5R, false)}
                                </div>
                            </div>
                        </div>
                </div>
            ) : 'Select a Game'}

                {dropdownOpen && (
                    <div
                        style={{
                            border: '1px solid #ccc',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 100,
                            width: '700px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            top: '67px',
                        }}
                    >
                        {matchData.map((game) => {
                            const blueTeamOrderDict = {
                                [game.B1R]: "BP1",
                                [game.B2R]: "BP2",
                                [game.B3R]: "BP3",
                                [game.B4R]: "BP4",
                                [game.B5R]: "BP5",
                            };

                            const redTeamOrderDict = {
                                [game.R1R]: "RP1",
                                [game.R2R]: "RP2",
                                [game.R3R]: "RP3",
                                [game.R4R]: "RP4",
                                [game.R5R]: "RP5",
                            };

                            return (
                                <div
                                    key={game.gameId}
                                    onClick={() => handleGameSelect(game)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        transition: 'background 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.background = '#f0f0f0'}
                                    onMouseLeave={(e) => e.currentTarget.style.background = 'white'}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{ width: '120px', textAlign: 'center' }}>
                                            {game.leagueName} {game.patch}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.B1R, game.B2R, game.B3R, game.B4R, game.B5R, true)}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                                <img className="small-image" src={TeamIcons[game.team1]} alt={`Team ${game.team1}`} style={{ width: '35px', marginRight: '10px' }} />
                                                <span style={{ fontWeight: 'bold' }}>{game.team1} vs {game.team2}</span>
                                                <img className="small-image" src={TeamIcons[game.team2]} alt={`Team ${game.team2}`} style={{ width: '35px', marginLeft: '10px' }} />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.R1R, game.R2R, game.R3R, game.R4R, game.R5R, false)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>


        <div style={{alignItems: 'center',  position: 'relative', width: "100%", display: 'flex', flexDirection: 'column'}}>
            <div id="map-container" className="map-container" style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>

            <img src={mapPhotsDictionary["SummonersRift"]} alt='Summoner’s Rift' style={{  position: 'relative', width: "500px", height: "500px" }} />

            {wardData && wardData.filter((ward) => {return ward.placeTime <= currentTime && (!ward.killTime || ward.killTime >= currentTime);}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-5 + 'px',
                        left: newX-5 + 'px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: position.side,
                        borderRadius: '50%',
                        color: 'white'
                        }}
                    >
                        {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                    </div>
                );
                }
            )}
            {playerPosition && playerPosition.filter((ward) => {return ward.time === currentTime;}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-11 + 'px',
                        left: newX-11 + 'px',
                        width: '22px',
                        height: '22px',
                        backgroundColor: position.side,
                        borderRadius: '100%',
                        }}
                    >
                        <img style={{width: "20px", height: "20px", position: 'absolute', borderRadius: '50%', top: 1 + 'px', left: 1 + 'px',}} src={championPhotos[position.championName]} alt=""/>
                    </div>
                );
                }
            )}
            </div>
            <input
                    type="range"
                    min="0"
                    max="180"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    style={{width: "500px"}}
                    />
            <div style={{ color: "white", textAlign: 'center' }}>
                {Math.floor(currentTime / 60)}:{String(currentTime % 60).padStart(2, '0')}
            </div>

            <div style={{display: 'flex'}}>

                <input
                    type="text"
                    value={timePoint1}
                    onChange={e => handleTimeChange(e, setTimePoint1)}
                    placeholder="M:SS"
                    style={{ width: "60px", marginRight: "10px" }}
                    />
                <label style={{color: 'white'}}> - </label>
                <input
                type="text"
                value={timePoint2}
                onChange={e => handleTimeChange(e, setTimePoint2)}
                placeholder="M:SS"
                style={{ width: "60px", marginLeft: "10px" }}
                />
                <button
                className="gif-button"
                onClick={makeGif}
                disabled={gifLoading}
                >
                {gifLoading ? "Processing..." : "Make GIF"}
                </button>
            </div>
        </div>
        <div id="level1-container" style={{display: 'flex', width: "100%"}}>
            <div style={{maxWidth: "33%",position: 'relative'}}>
                <input
                style={{width: "100%", textAlign: "center"}}
                value={inputTime}
                onChange={(e) => setInputTime(e.target.value)}>

                </input>
                <img style={{width: "100%"}} src={mapPhotsDictionary["SummonersRift"]} alt='Summoner’s Rift'/>
                {wardData && wardData.filter((ward) => {return ward.placeTime <= inputTime && (!ward.killTime || ward.killTime >= inputTime);}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-5 + 'px',
                        left: newX-5 + 'px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: position.side,
                        borderRadius: '50%',
                        color: 'white'
                        }}
                    >
                        {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                    </div>
                );
                }
            )}
            {playerPosition && playerPosition.filter((ward) => {return ward.time == inputTime;}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-11 + 'px',
                        left: newX-11 + 'px',
                        width: '22px',
                        height: '22px',
                        backgroundColor: position.side,
                        borderRadius: '100%',
                        }}
                    >
                        <img style={{width: "20px", height: "20px", position: 'absolute', borderRadius: '50%', top: 1 + 'px', left: 1 + 'px',}} src={championPhotos[position.championName]} alt=""/>
                    </div>
                );
                }
            )}
            </div>
            <div style={{maxWidth: "33%" , position: 'relative',}}>
                <input
                style={{width: "100%", textAlign: "center"}}
                value={inputTime2}
                onChange={(e) => setInputTime2(e.target.value)}>

                </input>
                <img style={{width: "100%"}} src={mapPhotsDictionary["SummonersRift"]} alt='Summoner’s Rift'/>
                {wardData && wardData.filter((ward) => {return ward.placeTime <= inputTime2 && (!ward.killTime || ward.killTime >= inputTime2);}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-5 + 'px',
                        left: newX-5 + 'px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: position.side,
                        borderRadius: '50%',
                        color: 'white'
                        }}
                    >
                        {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                    </div>
                );
                }
            )}
            {playerPosition && playerPosition.filter((ward) => {return ward.time == inputTime2;}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-11 + 'px',
                        left: newX-11 + 'px',
                        width: '22px',
                        height: '22px',
                        backgroundColor: position.side,
                        borderRadius: '100%',
                        }}
                    >
                        <img style={{width: "20px", height: "20px", position: 'absolute', borderRadius: '50%', top: 1 + 'px', left: 1 + 'px',}} src={championPhotos[position.championName]} alt=""/>
                    </div>
                );
                }
            )}
            </div>
            <div style={{maxWidth: "33%", position: 'relative',}}>
                <input
                style={{width: "100%", textAlign: "center"}}
                value={inputTime3}
                onChange={(e) => setInputTime3(e.target.value)}>
                </input>
                <img style={{width: "100%"}} src={mapPhotsDictionary["SummonersRift"]} alt='Summoner’s Rift'/>
                {wardData && wardData.filter((ward) => {return ward.placeTime <= inputTime3 && (!ward.killTime || ward.killTime >= inputTime3);}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-5 + 'px',
                        left: newX-5 + 'px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: position.side,
                        borderRadius: '50%',
                        color: 'white'
                        }}
                    >
                        {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                    </div>
                );
                }
            )}
            {playerPosition && playerPosition.filter((ward) => {return ward.time == inputTime3;}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-11 + 'px',
                        left: newX-11 + 'px',
                        width: '22px',
                        height: '22px',
                        backgroundColor: position.side,
                        borderRadius: '100%',
                        }}
                    >
                        <img style={{width: "20px", height: "20px", position: 'absolute', borderRadius: '50%', top: 1 + 'px', left: 1 + 'px',}} src={championPhotos[position.championName]} alt=""/>
                    </div>
                );
                }
            )}
            </div>
        </div>
        <button className="screenshot-button" onClick={() => captureScreenshot('level1-container')}>ScreenShot</button>

    </div>
  );
};

export default Level1Component;
