import React, { useEffect, useState } from 'react';
import { useSeason } from './SeasonContext';
import { TeamIcons } from './TeamIcons';
import { championPhotos } from './ChampionsIcon';
import roleIcon from './Role';
import axiosInstance from './backend/axiosInstance';
const ChampPoolComponent = () => {
  const { currentSeasonTime } = useSeason();

  const [selectedteam1, setSelectedteam1] = useState('');
  const [selectedteam2, setSelectedteam2] = useState('');
  const [selectedteam1Data, setSelectedteam1Data] = useState([]);
  const [selectedteam2Data, setSelectedteam2Data] = useState([]);
  const [teamOption, setTeamOption] = useState([]);
  const [comparisonResult, setComparisonResult] = useState({});
  const [selectedPlayers, setSelectedPlayers] = useState({
    team1: {},
    team2: {}
  });

  useEffect(() => {
    axiosInstance
      .get('unique_teames/')
      .then((response) => {
        const formattedTeamOptions = response.data.map((team) => ({
          value: team,
          label: team,
        }));
        setTeamOption(formattedTeamOptions);
      })
      .catch((error) => {
        console.error('Error fetching team options:', error);
      });
  }, [currentSeasonTime]);

  useEffect(() => {
    if (selectedteam1 && selectedteam2) {
      const [start, end] = currentSeasonTime.split(' - ').map((date) => date.trim());

      const fetchPlayers1 = axiosInstance.get(
        `players/?team=${selectedteam1}&start=${start}&end=${end}`
      );
      const fetchPlayers2 = axiosInstance.get(
        `players/?team=${selectedteam2}&start=${start}&end=${end}`
      );

      Promise.all([fetchPlayers1, fetchPlayers2])
        .then(([fetchPlayers1Response, fetchPlayers2Response]) => {
          setSelectedteam1Data(fetchPlayers1Response.data);
          setSelectedteam2Data(fetchPlayers2Response.data);
        })
        .catch((error) => {
          console.error('Error fetching team data:', error);
        });
    }
  }, [selectedteam1, selectedteam2, currentSeasonTime]);

  useEffect(()=> {
    document.title = 'ChampPool Comparison';
    compareTeams(selectedteam1Data, selectedteam2Data);
  }, [selectedteam1Data, selectedteam2Data])

  const compareTeams = (team1Data, team2Data, updatedPlayers) => {
    const team1ByRole = groupPlayersByRole(team1Data);
    const team2ByRole = groupPlayersByRole(team2Data);
    const roleComparison = {};

    Object.keys(team1ByRole).forEach((role) => {
      const team1Players = [...new Set(team1ByRole[role].map((player) => player.summonerName))];
      const team2Players = [...new Set(team2ByRole[role].map((player) => player.summonerName))];

      const selectedTeam1Player = updatedPlayers?.team1?.[role] || team1Players[0];
      const selectedTeam2Player = updatedPlayers?.team2?.[role] || team2Players[0];

      const filteredTeam1Champions = new Set(
        team1ByRole[role]
          .filter((player) => player.summonerName === selectedTeam1Player)
          .map((player) => player.championName));

      const filteredTeam2Champions = new Set(
        team2ByRole[role]
          .filter((player) => player.summonerName === selectedTeam2Player)
          .map((player) => player.championName));

      const commonChampions = [...filteredTeam1Champions].filter((champ) =>
        filteredTeam2Champions.has(champ));

      const uniqueTeam1Champions = [...filteredTeam1Champions].filter(
        (champ) => !filteredTeam2Champions.has(champ));

      const uniqueTeam2Champions = [...filteredTeam2Champions].filter(
        (champ) => !filteredTeam1Champions.has(champ));

      roleComparison[role] = {
        common: commonChampions,
        uniqueTeam1: uniqueTeam1Champions,
        uniqueTeam2: uniqueTeam2Champions,
        Players1: team1Players,
        Players2: team2Players,
      };
    });

    setComparisonResult(roleComparison);
  };

  const groupPlayersByRole = (teamData) => {
    const playersByRole = {};
    teamData.forEach((player) => {
      const { Role, summonerName, championName } = player;
      if (!playersByRole[Role]) {
        playersByRole[Role] = [];
      }
      playersByRole[Role].push({ summonerName, championName });
    });
    return playersByRole;
  };

  const handleTeam1Change = (event) => {
    setSelectedteam1(event.target.value);
  };

  const handleTeam2Change = (event) => {
    setSelectedteam2(event.target.value);
  };

  const handlePlayerChange = (role, team, summonerName) => {
    setSelectedPlayers((prevSelectedPlayers) => {
      const updatedPlayers = {
        ...prevSelectedPlayers,
        [team]: {
          ...prevSelectedPlayers[team],
          [role]: summonerName
        }
      };
      compareTeams(selectedteam1Data, selectedteam2Data, updatedPlayers);
      return updatedPlayers;
    });
  };

  const renderPlayerDropdown = (role, team, players) => {
    const selectedPlayer = selectedPlayers[team][role] || '';
    return (
      <select
        style={{width: "100%", textAlign: "center"}}
        value={selectedPlayer}
        onChange={(e) => handlePlayerChange(role, team, e.target.value)}
      >
        {players.map((player) => (
          <option key={player} value={player}>
            {player}
          </option>
        ))}
      </select>
    );
  };

  const renderChampionImages = (champions) => {
    return champions.map((champion) => (
      <img
        key={champion}
        src={championPhotos[champion] || '/default-champion.png'}
        alt={champion}
        style={{ padding: '2px', width: '40px' }}
      />
    ));
  };

  return (
    <div className="wrapper">
      <div className="filters">
        <div className="team-selector">
          <label>Team 1</label>
          <select value={selectedteam1} onChange={handleTeam1Change}>
            <option value="" disabled>
              Select a team
            </option>
            {teamOption.map((team) => (
              <option key={team.value} value={team.value}>
                {team.label}
              </option>
            ))}
          </select>
        </div>
        <div className="team-selector">
          <label>Team 2</label>
          <select value={selectedteam2} onChange={handleTeam2Change}>
            <option value="" disabled>
              Select a team
            </option>
            {teamOption.map((team) => (
              <option key={team.value} value={team.value}>
                {team.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Render comparison results */}
      <div style={{ width: '100%', backgroundColor: 'white' }}>
        <table>
          <thead>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '10%' }}>Role</th>
              <th style={{ width: '26%' }}>
                <img
                  src={TeamIcons[selectedteam1]}
                  alt=""
                  className="small-image"
                />
                {selectedteam1} Unique Champions
              </th>
              <th style={{ width: '28%' }}>Common Champions</th>
              <th style={{ width: '26%' }}>
                <img
                  src={TeamIcons[selectedteam2]}
                  alt=""
                  className="small-image"
                />
                {selectedteam2} Unique Champions
              </th>
              <th style={{ width: '10%' }}>Role</th>

            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            {Object.keys(comparisonResult).map((role) => (
              <tr key={role}>
                <td>
                  <img src={roleIcon[role]} alt={role} style={{ width: '40px' }} />
                  {renderPlayerDropdown(role, 'team1', comparisonResult[role].Players1)}
                </td>
                <td>
                  {renderChampionImages(comparisonResult[role].uniqueTeam1)}
                </td>
                <td>
                  {renderChampionImages(comparisonResult[role].common)}
                </td>
                <td>
                  {renderChampionImages(comparisonResult[role].uniqueTeam2)}
                </td>
                <td>
                  <img src={roleIcon[role]} alt={role} style={{ width: '40px' }} />
                  {renderPlayerDropdown(role, 'team2', comparisonResult[role].Players2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChampPoolComponent;
