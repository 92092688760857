import React, { useEffect, useState } from 'react';
import "./Scrims.css";
import { Chart, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { championPhotos } from "./ChampionsIcon";
import axiosInstance from './backend/axiosInstance';
// Register the linear scale
Chart.register(...registerables);

Chart.register({
    id: 'customLegend',
    afterUpdate: chart => {
      if (chart.config.type === 'doughnut') {
        chart.legend.options.display = false;
      }
    }
  });

const ScrimsModule = () => {
    const [data, setData] = useState({ Red: { FirstHerald: 0, FirstDragon: 0, FirstTower: 0, Games: 0, WinRate: 0 }, Blue: { FirstHerald: 0, FirstDragon: 0, FirstTower: 0, Games: 0, WinRate: 0 } });
    const [scrimsData, setScrimsData] = useState([]);
    const [championData, setChampionData] = useState([]);
    useEffect(() => {
      axiosInstance
        .get('performances/')
        .then(response => {
          const data = response.data;
          const teamName = 'ESCA';
          let redGamesPlayed = 0;
          let redFirstHerald = 0;
          let redFirstDragon = 0;
          let redFirstTower = 0;
          let blueGamesPlayed = 0;
          let blueFirstHerald = 0;
          let blueFirstDragon = 0;
          let blueFirstTower = 0;
          let redWinRate = 0;
          let blueWinRate = 0;
          data.forEach(match => {
            if (match.teamName === teamName) {
              if (match.side === 'red') {
                redGamesPlayed++;
                redFirstHerald += match.firstHerald;
                redFirstDragon += match.firstDragon;
                redFirstTower += match.firstTower;
                redWinRate += match.winner;
              } else if (match.side === 'blue') {
                blueGamesPlayed++;
                blueFirstHerald += match.firstHerald;
                blueFirstDragon += match.firstDragon;
                blueFirstTower += match.firstTower;
                blueWinRate += match.winner;
              }
            }
          });

          setData({
            Red: {
              FirstHerald: (redFirstHerald / redGamesPlayed) * 100,
              FirstDragon: (redFirstDragon / redGamesPlayed) * 100,
              FirstTower: (redFirstTower / redGamesPlayed) * 100,
              Games: redGamesPlayed,
              WinRate: (redWinRate / (redGamesPlayed)) * 100
            },
            Blue: {
              FirstHerald: (blueFirstHerald / blueGamesPlayed) * 100,
              FirstDragon: (blueFirstDragon / blueGamesPlayed) * 100,
              FirstTower: (blueFirstTower / blueGamesPlayed) * 100,
              Games: blueGamesPlayed,
              WinRate: (blueWinRate / (blueGamesPlayed)) * 100
            },
          });
        })
        .catch(error => {
          console.error(error);
        });
    }, []);

    useEffect(() => {
      axiosInstance
        .get('parsedscrims/')
        .then(response => {
          // Map the values
          const mappedData = response.data.map(item => {
            return Object.entries(item).map(([key, value]) => ({ key, value }));
          });

          // Update the state with the mapped values
          setScrimsData(mappedData);
          console.log(mappedData)
        })
        .catch(error => {
          console.error(error);
        });
    }, []);

    useEffect(() => {
      axiosInstance
        .get('champions/')
        .then(response => {
          setChampionData(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }, []);

  return (
    <div className="scrims-module">
      <div className="main-container">
        <div className="container">
        <table>
  <thead>
    <tr>
      <th className="column">A</th>
      <th className="column">B</th>
      <th className="column">C</th>
      <th className="column">D</th>
      <th className="column">E</th>
      <th className="column">F</th>
    </tr>
  </thead>
  <tbody>
  {Array.from({ length: 10 }, (_, index) => (
    <tr key={index}>
      {index < 3 ? (
        <>
          <td className="small-column">
            <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BB${index + 1}`)?.value)?.name]} alt="pic" className="small-image" />
          </td>
          <td className="medium-column" colSpan="2">
            {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BB${index + 1}`)?.value)?.name}
          </td>
        </>
      ) : index === 6 || index === 7 ? (
        <>
        <td className="small-column">
          <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BB${index - 2}`)?.value)?.name]} alt="pic" className="small-image" />
        </td>
          <td className="medium-column" colSpan="2">
            {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BB${index - 2}`)?.value)?.name}
          </td>
          </>
      ) : index === 8 || index === 9 ? (
        <>
        <td className="small-column">
          <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BP${index - 4}`)?.value)?.name]} alt="pic" className="small-image" />
        </td>
        <td className="medium-column" colSpan="1">
          {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BP${index - 4}`)?.value)?.name}
        </td>
          <td className="small-column"></td>
        </>
      ) : (
        <>
          <td className="small-column">
            <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BP${index - 2}`)?.value)?.name]} alt="pic" className="small-image" />
          </td>
          <td className="medium-column" colSpan="1">
            {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `BP${index - 2}`)?.value)?.name}
          </td>
          <td className="small-column"></td>
        </>
      )}
      {index < 3 ? (
      <>
        <td className="medium-column" colSpan="2">
          {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RB${index + 1}`)?.value)?.name}
        </td>
        <td className="small-column">
          <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RB${index + 1}`)?.value)?.name]} alt="pic" className="small-image" />
        </td>
      </>
      ) : index === 6 || index === 7 ? (
      <>
        <td className="medium-column" colSpan="2">
          {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RB${index - 2}`)?.value)?.name}
        </td>
        <td className="small-column">
          <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RB${index - 2}`)?.value)?.name]} alt="pic" className="small-image" />
        </td>
      </>
      ) : index === 8 || index === 9 ? (
        <>
        <td className="small-column"></td>
        <td className="medium-column" colSpan="1">
          {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RP${index - 4}`)?.value)?.name}
        </td>
        <td className="small-column">
        <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RP${index - 4}`)?.value)?.name]} alt="pic" className="small-image" />
      </td>
        </>
      ) : (
        <>
        <td className="small-column"></td>
        <td className="medium-column" colSpan="1">
          {championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RP${index - 2}`)?.value)?.name}
        </td>
        <td className="small-column">
        <img src={championPhotos[championData.find(champion => champion.champion_id === scrimsData[0]?.find(item => item.key === `RP${index - 2}`)?.value)?.name]} alt="pic" className="small-image" />
      </td>
         </>
      )}
    </tr>
  ))}
</tbody>
        </table>

        </div>
        <div className="container">

        </div>
        <div className="container">

        </div>
      </div>


      <div className="small-containers">
        <div className="small-container">
            <h3>Blue Side</h3>
            <div className="squared-container">
            <Doughnut
                data={{
                labels: ['First Herald', "No First Herald"],
                datasets: [
                    {
                    label: 'idk',
                    data: [data.Blue.FirstHerald, 100-data.Blue.FirstHerald],
                    backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                    },
                ],
                }}
            />
            </div>
            <div className="squared-container">
                <Doughnut
                 data={{
                labels: ['First Tower', "No First Tower"],
                datasets: [
                    {
                    label: 'idk',
                    data: [data.Blue.FirstTower, 100-data.Blue.FirstTower],
                    backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                    },
                ],
                }}
            />
            </div>
            <div className="squared-container">
                <Doughnut
                     data={{
                    labels: ['First Dragon', "No First Dragon"],
                    datasets: [
                        {
                        label: 'idk',
                        data: [data.Blue.FirstDragon, 100-data.Blue.FirstDragon],
                        backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                        },
                    ],
                    }}
                />
            </div>
            <div className="squared-container">
                <Doughnut
                         data={{
                        labels: ['Winning', "Losing"],
                        datasets: [
                            {
                            label: 'idk',
                            data: [data.Blue.WinRate, 100-data.Blue.WinRate],
                            backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                            },
                        ]
                        }}
                        options={{
                            legend: {
                              display: false,
                            }
                          }}
                />
            </div>
        </div>
        xd
        <div className="small-container">
            <h3>Red Side</h3>
            <p style={{ textAlign: 'center' }}>Herald      Dragon         Tower           WinRate</p>
            <div className="squared-container">
            <Doughnut
               data={{
                labels: ['First Herald', "No First Herald"],
                datasets: [
                    {
                    label: 'idk',
                    data: [data.Red.FirstHerald, 100-data.Red.FirstHerald],
                    backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                    },
                ],
                }}
            />
            </div>
            <div className="squared-container">
                <Doughnut
                 data={{
                labels: ['First Tower', "No First Tower"],
                datasets: [
                    {
                    label: 'idk',
                    data: [data.Red.FirstTower, 100-data.Red.FirstTower],
                    backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                    },
                ],
                }}
            />
            </div>
            <div className="squared-container">
                <Doughnut
                     data={{
                    labels: ['First Dragon', "No First Dragon"],
                    datasets: [
                        {
                        label: 'idk',
                        data: [data.Red.FirstDragon, 100-data.Red.FirstDragon],
                        backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                        },
                    ],
                    }}
                />
            </div>
            <div className="squared-container">
                <Doughnut
                         data={{
                        labels: ['Winning', "Losing"],
                        datasets: [
                            {
                            label: 'idk',
                            data: [data.Red.WinRate, 100-data.Red.WinRate],
                            backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                            },
                        ]
                        }}
                        options={{
                            legend: {
                              display: false,
                            },
                            plugins: {
                                datalabels: {
                                  formatter: (value) => {
                                    return value + '%';
                                  },
                                },
                              },
                          }}
                />
            </div>
        </div>
        <div className="small-container">
            xd1
        </div>
      </div>
    </div>
  );
};

export default ScrimsModule;
