import React, { useEffect, useState } from 'react';
import CustomSelect from './CustomSelect';
import Pagination from 'react-bootstrap/Pagination';
import "bootstrap/dist/css/bootstrap.min.css";
import "./ScrimHistory.css";
import { championPhotos, championData} from "./ChampionsIcon";
import { TeamIcons } from './TeamIcons';
import { Link } from 'react-router-dom';
import iconDictionary from './Icons';
import roleIcon from './Role';
import axiosInstance from './backend/axiosInstance';

const ScrimHistoryComponent = ({ initialTeamName }) => {

  document.title = "Scrim History";
  const PAGE_SIZE = 10;
  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [teamOption, setTeamOption] = useState([]);

  const [selectedChampion, setSelectedChampion] = useState([]);
  const [filterChampion, setFilterChampion] = useState([]);
  const [championOption, setChampionOption] = useState([]);

  const [matchData, setMatchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [matchesForCurrentPage, setMatchesForCurrentPage] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [showDrafts, setShowDrafts] = useState(false);

  const getUniqueTeams = (matchData) => {
    const teamsSet = new Set();
    matchData.forEach((match) => {
      teamsSet.add(match.team1);
      teamsSet.add(match.team2);
    });
    return [...teamsSet];
  };

  useEffect(() => {
    // Fetch data from the API and update the 'data' state with the response
    axiosInstance
      .get('parsedscrims/?team='+localStorage.getItem("team"))
      .then((response) => {
        const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setMatchData(sortedMatchData);
        const uniqueTeamsForLeague = getUniqueTeams(sortedMatchData);
        const uniquePatches = [...new Set(sortedMatchData.map((match) => match.patch))];
        const formattedTeamOptions = uniqueTeamsForLeague
        .sort((a, b) => a.localeCompare(b)) // Sort the team names alphabetically
        .map((team) => ({
          value: team,
          label: team,
        }));
        setTeamOption(formattedTeamOptions);

        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        const uniqueTeams = getUniqueTeams(sortedMatchData);
        if (initialTeamName) {
          const matchedTeam = formattedTeamOptions.find(option => option.value === initialTeamName);
          if (matchedTeam) {
            handleTeamChange([matchedTeam]);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      const formattedChampionOptions = Object.entries(championData).map(([id, champion]) => ({
        value: id,
        label: champion,
      }));
      formattedChampionOptions.sort((a, b) => a.label.localeCompare(b.label));

      setChampionOption(formattedChampionOptions)
  }, []);


  useEffect(() => {
    // Calculate the total number of pages based on the filtered matchData and PAGE_SIZE
    const filteredMatches = matchData.filter((match) => {
      const isPatchMatch = filterPatch.length === 0 || filterPatch.includes(match.patch);
      const isTeamMatch = filterTeam.length === 0 || filterTeam.includes(match.team1) || filterTeam.includes(match.team2);
      const isDuration = match.gameDuration > 60 * 10;
      // Update the champion filtering logic to use the champion IDs as numbers
      const matchChampions = [
        match.BP1, match.BP2, match.BP3, match.BP4, match.BP5, match.RP1, match.RP2, match.RP3, match.RP4, match.RP5
      ].filter(Boolean); // Remove null or undefined values

      const isChampionMatch =
        filterChampion.length === 0 ||
        filterChampion.every(championId =>
          matchChampions.includes(Number(championId)) // Check if all selected champions are present in the match
        );

      return isPatchMatch && isTeamMatch && isChampionMatch && isDuration;
    });
    const totalMatches = filteredMatches.length;
    const totalPages = Math.ceil(totalMatches / PAGE_SIZE);
    setTotalPages(totalPages);

    // Ensure the current page stays within the valid range (1 to totalPages) when filters change
    setCurrentPage((prevCurrentPage) => {
      const lastValidPage = Math.min(prevCurrentPage, totalPages);
      return lastValidPage >= 1 ? lastValidPage : 1;
    });



    // Update the matches to be displayed for the current page
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, totalMatches); // Ensure endIndex doesn't exceed totalMatches
    const matchesForCurrentPage = filteredMatches.slice(startIndex, endIndex);
    setMatchesForCurrentPage(matchesForCurrentPage);

    const uniqueTeams = getUniqueTeams(filteredMatches);
    const formattedTeamOptions = uniqueTeams.map((team) => ({
      value: team,
      label: team,
    }));
    setTeamOption(formattedTeamOptions);
}, [currentPage, matchData, filterPatch, filterTeam, filterChampion, PAGE_SIZE]);

  const handleTeamChange = (selectedOptions) => {
    setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterTeam(leagueValues);
    setSelectedTeam(selectedOptions);
  };

  const handlePatchChange = (selectedOptions) => {
    setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleChampionChange = (selectedOptions) => {
    setMatchesForCurrentPage([]);
    const championValues = selectedOptions.map((option) => option.value);
    const championIds = championValues.map(Number);
    setFilterChampion(championIds);
    setSelectedChampion(selectedOptions);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber === currentPage) { // Check if the clicked page is the current page
      return; // Do nothing if the current page is clicked
    }
    setMatchesForCurrentPage([]);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const ellipsis = <Pagination.Ellipsis />;

    paginationItems.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
    );

    if (totalPages <= 5) {
      // If total pages are less than or equal to 5, display all pages
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Display the first 2 pages and the last 2 pages
      for (let i = 1; i <= 2; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }

      // Display the current page and the last page, and ellipsis in between if needed
      if (currentPage - 2 > 2) {
        paginationItems.push(ellipsis);
      }
      for (let i = Math.max(currentPage - 2, 3); i <= Math.min(currentPage + 2, totalPages - 2); i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }
      if (currentPage + 2 < totalPages - 1) {
        paginationItems.push(ellipsis);
      }

      for (let i = totalPages - 1; i <= totalPages; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }
    }

    // Always display the next page and last page if needed
    paginationItems.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
    );

    return paginationItems;
  };

  return (
    <div>
      <div className='filters'>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
        Team
        <div className='short-chooser'>
          <CustomSelect options={teamOption} selectedOption={selectedTeam} onOptionChange={handleTeamChange} />
        </div>
        Champion
        <div className='short-chooser'>
          <CustomSelect options={championOption} selectedOption={selectedChampion} onOptionChange={handleChampionChange} />
        </div>
        {"Show Drafts"}
        <label>
          <input
            type="checkbox"
            checked={showDrafts}
            onChange={() => setShowDrafts(!showDrafts)}
          />
        </label>
      </div>
      <div className='main-content'>
        <table>
            <thead>
            <tr>
                <th>id</th>
                <th>date</th>
                <th>blue</th>
                <th>red</th>
                <th>winner</th>
                <th>patch</th>
                <th>champions</th>
                <th>champions</th>
                <th>link</th>
                <th>:|</th>
            </tr>
            </thead>
        </table>
        {matchesForCurrentPage.map((match, index) => {
          // Create an array to hold the order of blue team champions (0-4)
          const blueTeamOrderDict = {
            [match.B1R]: "BP1",
            [match.B2R]: "BP2",
            [match.B3R]: "BP3",
            [match.B4R]: "BP4",
            [match.B5R]: "BP5",
          };

          // Create a dictionary to hold the order of red team champions (0-4) and sort it by values in descending order
          const redTeamOrderDict = {
            [match.R1R]: "RP1",
            [match.R2R]: "RP2",
            [match.R3R]: "RP3",
            [match.R4R]: "RP4",
            [match.R5R]: "RP5",
          };
          const filename = match.Link?.split('/').pop().split('?')[0];
          const winnerTeam = match.winner === 100 ? match.team1 : ( match.winner === 200 ? match.team2 : "No FF");
          return (
            <div style={{textDecoration: "none", color: 'inherit'}} key={match.gameId} className='match-container'>
              <Link to={`/scrim/${match.scrimId}`}   style={{textDecoration: "none", color: 'inherit'}} className='match-id'>{index + 1 + (currentPage-1) * PAGE_SIZE} </Link>
              <Link to={`/scrim/${match.scrimId}`}   style={{textDecoration: "none", color: 'inherit'}} className='match-league'> {new Date(match.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })} </Link>
              <Link to={`/team/${match.team1}`} style={{textDecoration: "none", color: 'inherit'}}  className='match-team'>
              <img src={TeamIcons[match.team1]} alt='' className="small-image"/>{match.team1} </Link>
              <Link to={`/team/${match.team2}`} style={{textDecoration: "none", color: 'inherit'}}  className='match-team'>
              <img src={TeamIcons[match.team2]} alt='' className="small-image"/>{match.team2} </Link>
              <Link to={`/team/${winnerTeam}`} style={{textDecoration: "none", color: 'inherit'}}  className='match-team'>
              <img src={TeamIcons[winnerTeam]} alt='' className="small-image"/>{winnerTeam} </Link>
              <Link  to={`/scrim/${match.scrimId}`}  style={{textDecoration: "none", color: 'inherit'}} className='match-patch'>{match.patch} </Link>
              {!showDrafts ? (
              <div style={{display: 'flex', width: "40%",  justifyContent: 'space-around'}}>
              <Link to={`/match/${match.gameId}`} style={{textDecoration: "none", color: 'inherit', display: 'flex'}} >
                {[0, 1, 2, 3, 4].map((blueIndex) => (
                    <div key={blueIndex} style={{display: 'flex', alignItems: "center", flexDirection: 'column'}}>
                    <img src={roleIcon[blueIndex]}
                        alt={""}
                        style={{width: "15px"}}
                        />
                    <img
                        src={championPhotos[championData[match[(blueTeamOrderDict[blueIndex])]]]}
                        alt={""}
                        className="small-image"
                        />
                    </div>
                ))}
              </Link>
              <Link to={`/match/${match.gameId}`} style={{textDecoration: "none", display: 'flex', color: 'inherit'}} >
                {[0, 1, 2, 3, 4].map((blueIndex) => (
                    <div key={blueIndex} style={{display: 'flex', alignItems: "center", flexDirection: 'column'}}>
                    <img src={roleIcon[blueIndex]}
                        alt={""}
                        style={{width: "15px"}}
                        />
                    <img
                        src={championPhotos[championData[match[(redTeamOrderDict[blueIndex])]]]}
                        alt={""}
                        className="small-image"
                        />
                    </div>
                ))}
              </Link>
            </div>

              ):(
              <div style={{display: 'flex', width: "40%",  flexDirection: 'column',   justifyContent: 'space-around'}}>
                <div style={{display:"flex"}}>
                  <div style={{width: "40px", color: 'blue'}}>
                    {"BLUE"}
                  </div>
                  {[1, 2, 3].map((blueIndex) => (
                      <React.Fragment key={blueIndex}>
                      <img
                          src={championPhotos[championData[match['BB' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                          />

                      </React.Fragment>
                  ))}
                  <div style={{width: "5px"}}>
                  </div>
                  <img
                          src={championPhotos[championData[match['BP1']]]}
                          alt={""}
                          className="small-image"
                          />
                  <div style={{width: "5px"}}/>
                  {[2, 3].map((blueIndex) => (
                      <React.Fragment key={blueIndex}>
                      <img
                          src={championPhotos[championData[match['BP' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                          />

                      </React.Fragment>
                  ))}
                  <div style={{width: "5px"}}/>

                  {[4, 5].map((blueIndex) => (
                      <React.Fragment key={blueIndex}>
                      <img
                          src={championPhotos[championData[match['BB' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                          />

                      </React.Fragment>
                  ))}
                  <div style={{width: "5px"}}/>
                  <div style={{width: "5px"}}/>
                  {[4, 5].map((blueIndex) => (
                      <React.Fragment key={blueIndex}>
                      <img
                          src={championPhotos[championData[match['BP' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                          />

                      </React.Fragment>
                  ))}
                </div>
                <div style={{display:"flex"}}>
                  <div style={{width: "40px", color: "red"}}>
                    {"RED"}
                  </div>
                  {[1, 2, 3].map((blueIndex) => (
                      <React.Fragment key={blueIndex} style={{borderRightWidth: "3px", borderRightColor: 'white'}}>
                      <img
                          src={championPhotos[championData[match['RB' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                        />

                      </React.Fragment>
                  ))}
                  <div style={{width: "5px"}}/>
                  {[1, 2, ].map((blueIndex) => (
                      <React.Fragment key={blueIndex}>
                      <img
                          src={championPhotos[championData[match['RP' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                          />

                      </React.Fragment>
                  ))}
                  <div style={{width: "5px"}}/>
                  <img src={championPhotos[championData[match['RP3']]]} alt={""} className="small-image"/>
                  <div style={{width: "5px"}}/>
                  {[4, 5].map((blueIndex) => (
                      <React.Fragment key={blueIndex}>
                      <img
                          src={championPhotos[championData[match['RB' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                          />

                      </React.Fragment>
                  ))}
                  <div style={{width: "5px"}}/>
                  {[4].map((blueIndex) => (
                      <React.Fragment key={blueIndex}>
                      <img
                          src={championPhotos[championData[match['RP' + blueIndex ]]]}
                          alt={""}
                          className="small-image"
                          />

                      </React.Fragment>
                  ))}
                  <div style={{width: "5px"}}/>
                  <img src={championPhotos[championData[match['RP5']]]} alt={""} className="small-image"/>
                  <div style={{width: "5px"}}/>
                </div>
              </div>
              )}
                <div >
                    <a className="button-link" href={match.Link} download={filename}>
                        Download
                    </a>
                </div>
                {"  "}
              <></>
              <Link to={`/mapScrim/${match.scrimId}`} style={{textDecoration: "none", color: 'inherit', display:'flex', justifyContent:"space-around", flexDirection: "column", paddingLeft: "5px"}}>
                  <img src={ iconDictionary["mapka"] } alt="" style={{width: '30px'}}/>
              </Link>
            </div>
          );
        })}
        <div className='main-pagination'>
          <Pagination  style={{ display: 'flex', justifyContent: 'center' }}>{renderPaginationItems()}</Pagination>
        </div>
      </div>
    </div>
  );
};

export default ScrimHistoryComponent;
