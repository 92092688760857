import React, { useEffect, useState } from 'react';
import { useSeason } from './SeasonContext';
import rankIconDictionary from './RankIcons';
import iconDictionary from './Icons';
import Flag from 'react-world-flags';
import roleIcon from './Role';
import CountryCustomSelect from './CountryCustomSelect';
import Cookies from 'js-cookie';
import axiosInstance from './backend/axiosInstance';

const LeaderboardComponent = () => {
  const { currentSeasonTime } = useSeason();
  const [accountData, setAccountData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('euw');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRole, setSelectedRole] = useState(-1);
  const [followedPlayers, setFollowedPlayers] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);

  const roleOptions = [
    { value: -1, label: 'All' }, // Adjust "All Roles" to use null
    { value: 0, label: 'Top' },
    { value: 1, label: 'Jgl' },
    { value: 2, label: 'Mid' },
    { value: 3, label: 'Bot' },
    { value: 4, label: 'Sup' },
  ];

  useEffect(() => {
    document.title = "Leaderboard"; // Setting the document title within useEffect

    if (selectedRegion) {
      const url = `account/?region=${selectedRegion}`;
      axiosInstance
        .get(url)
        .then(response => {
          setAccountData(response.data);
        })
        .catch(error => {
          console.error('Error fetching leagues:', error);
        });
    }
  }, [selectedRegion]);


  const handleFollowChange = (playerId) => {
    const userId = Cookies.get('id'); // Retrieve user ID from cookies
    const isFollowing = followedPlayers.has(playerId);
    const updatedFollowedPlayers = new Set(followedPlayers);

    const action = isFollowing ? 'unfollow' : 'follow';

    if (isFollowing) {
      updatedFollowedPlayers.delete(playerId);
    } else {
      updatedFollowedPlayers.add(playerId);
    }

    axiosInstance.post('follow/', {
      user_id: userId,
      player_id: playerId,
      action: action
    })
    .catch(error => console.error(`Error ${action === 'follow' ? 'following' : 'unfollowing'} player:`, error));

    setFollowedPlayers(updatedFollowedPlayers);
  };

  const fetchFollowedPlayers = async () => {
    const userId = Cookies.get('id'); // Retrieve user ID from cookies
    if (userId) {
      try {
        const response = await axiosInstance.get('follow/', {
          params: { user_id: userId }
        });
        const followedPlayers = new Set(response.data.map(player => player.player_id));
        setFollowedPlayers(followedPlayers);
      } catch (error) {
        console.error('Error fetching followed players:', error);
      }
    }
  };

  useEffect(() => {
    fetchFollowedPlayers();
  }, []);

  useEffect(() => {
    let filteredData = [...accountData];

    if (selectedCountry && selectedCountry.value !== 'all') {
      filteredData = filteredData.filter(account => account.country === selectedCountry.value);
    }
    if (selectedRole !== -1) {
      filteredData = filteredData.filter(account => account.role === selectedRole);
    }
    if (searchQuery) {
      filteredData = filteredData.filter(account =>
        account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.lastSummonerName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (showFavoritesOnly) {
      filteredData = filteredData.filter(account => followedPlayers.has(account.id));
    }

    setSortedData(filteredData);
  }, [accountData, selectedCountry, selectedRole, searchQuery, showFavoritesOnly, followedPlayers]);

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const getRedGreenCellno0 = (value, minValue, maxValue) => {
    if (value === (minValue + maxValue) / 2) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const midpoint = (minValue + maxValue) / 2;
      const normalizedValue = value > midpoint
        ? (value - midpoint) / (maxValue - midpoint)
        : (-value + midpoint) / (minValue + midpoint);
      const hue = value > midpoint ? 120 : 0; // Green for positive, Red for negative
      const lightness = 100 - 50 * normalizedValue;
      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  const countryOptions = [
    { value: 'all', label: 'All' }, // Add the "All" option
    ...Array.from(new Set(accountData.map(account => account.country).sort()))
      .filter(code => code)
      .map(country => ({
        value: country,
        label: country,
      }))
  ];

  return (
    <div style={{color: "white" }}>
      <div className='filters' style={{justifyContent: 'space-evenly'}}>
        <div style={{display: "flex"}}>
          <label htmlFor="region-select">Region:</label>
          <select
            id="region-select"
            value={selectedRegion}
            onChange={handleRegionChange}
          >
            <option value="euw">EUW</option>
            <option value="kr">KR</option>
            <option value="jp">JP</option>
            <option value="oce">OCE</option>
          </select>
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          <label htmlFor="search-input">Search:</label>
          <input
            id="search-input"
            type="text"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            placeholder="Search by name or account"
          />
        </div>
        <div style={{display: "flex"}}>
          <label htmlFor="role-select">Role:</label>
          <select
            id="role-select"
            value={selectedRole !== -1 ? selectedRole : -1}
            onChange={(event) => setSelectedRole(event.target.value === -1 ? -1 : Number(event.target.value))}
          >
            {roleOptions.map(role => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </select>
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          <label htmlFor="country-select">Country:</label>
          <CountryCustomSelect
            options={countryOptions}
            selectedOption={selectedCountry}
            onOptionChange={handleCountryChange}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            id="favorites-only-checkbox"
            checked={showFavoritesOnly}
            onChange={(event) => setShowFavoritesOnly(event.target.checked)}
          />
          <label htmlFor="favorites-only-checkbox" style={{ marginLeft: "8px" }}>
            Favorites Only
          </label>
        </div>
      </div>
      <br></br>
      <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
        <div style={{border: "2px solid rgb(0, 242, 255)"}}>
          <div style={{backgroundColor: "gray", display: 'flex', justifyContent: 'space-between', borderBottom: '4px solid white', }} >
            <div style={{width: "5%", paddingLeft: "10px"}}></div>
            <div style={{width: "17%", paddingLeft: "10px"}}>Name</div>
            <div style={{width: "23%"}}>Account</div>
            <div style={{width: "10%", textAlign: 'center'}}>Role</div>
            <div style={{width: "25%", textAlign: 'center'}}>Rank</div>
            <div style={{width: "10%", textAlign: 'center'}}>Games</div>
            <div style={{width: "10%", textAlign: 'center'}}></div>
          </div>
          {sortedData.filter((account) => account.tier !== "UNRANKED").map((account, index) => (
            <div key={account.id} style={{backgroundColor: followedPlayers.has(account.id) ? 'darkblue' : 'gray', display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',    padding: '5px 0'}}>
              <div style={{width: "5%", paddingLeft: "10px"}}>{index + 1}.</div>
              <div style={{width: "2%"}}>
                <Flag code={account.country} alt={account.country} style={{width: '30px', height: '30px'}} />
              </div>
              <div style={{width: "15%", paddingLeft: "10px"}}>{account.name}</div>
              <div style={{width: "23%", display: 'flex', justifyContent: "space-between"}}>
                <div>{account.lastSummonerName}</div>
                <div>
                  <button
                    key={account.puuid}
                    style={{width: "25px", height: "25px"}}
                    onClick={() => window.open(`https://na.op.gg/summoners/${account.opgg}/${account.lastSummonerName.replace(/#/g, '-')}`, '_blank')}
                    className="account-button"
                  >
                    <img style={{width: "25px", height: "25px"}} src={iconDictionary["opgg"]} alt="opgg icon"/>
                  </button>
                  <button
                    key={account.summoner_id}
                    style={{width: "25px", height: "25px"}}
                    onClick={() => window.open(`https://www.deeplol.gg/summoner/${account.opgg}/${account.lastSummonerName.replace(/#/g, '-')}`, '_blank')}
                    className="account-button"
                  >
                    <img style={{width: "25px", height: "25px"}} src={iconDictionary["deeplol"]} alt="deeplol icon"/>
                  </button>
                </div>
              </div>
              <div style={{width: "10%", paddingLeft: "10px", justifyContent: 'center', display: "flex"}}>
                <img src={roleIcon[account.role]} style={{width: "30px"}} alt="role icon"/>
              </div>
              <div style={{width: "25%", display:'flex', justifyContent: 'center'}}>
                <img style={{width: "30px"}} src={rankIconDictionary[account.tier]} alt='rank icon'/> {account.tier} {["MASTER", "CHALLENGER", "GRANDMASTER"].includes(account.tier) ? "" : account.ranks} {account.leaguePoints} LP
              </div>
              <div style={{width: "10%", display:'flex', justifyContent: 'center'}}>
                {account.wins} / {account.losses}
                <div style={{color: getRedGreenCellno0(account.wins/(account.wins+account.losses)*100, 0, 100)}}>
                  &nbsp;{(account.wins/(account.wins+account.losses)*100).toFixed(0)}%
                </div>
              </div>
              <div style={{width: "10%", textAlign: 'center', justifyContent: 'space-around', display: 'flex'}}>
              <input
                type="checkbox"
                checked={followedPlayers.has(account.id)}
                onChange={() => handleFollowChange(account.id)}
              />

            </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>
    </div>
  );
};

export default LeaderboardComponent;
