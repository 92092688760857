const teamIcons = {};

function importAll(r) {
  r.keys().forEach((key) => {
    const teamName = key.replace('./', '').replace('.png', '');
    teamIcons[teamName] = r(key);
  });
}

importAll(require.context('../team_icons', false, /\.png$/));

export const TeamIcons = teamIcons;
