import React, { useEffect, useState } from 'react';
import { championPhotos } from './ChampionsIcon'; // Assuming this is a mapping of champion names to their respective images
import iconDictionary from './Icons';
import SoloqPageComponent from './SoloqPage';
import CustomSelect from './CustomSelect';
import axiosInstance from './backend/axiosInstance';

const PlayerSoloqPageComponent = ({ name}) => {

  const [soloQData, setSoloQData] = useState([]);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [startDate, setStartDate] = useState(new Date('2024-05-14'));
  const [endDate, setEndDate] = useState(new Date());

  const getPlayerNameWithoutTeamTagLower = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' ').toLowerCase(); // Remove the team tag
  };


  useEffect(() => {
    const playerName = getPlayerNameWithoutTeamTagLower(name);

    axiosInstance.get(`soloQ/?playername=${playerName}`)
      .then(response => {
        const data = response.data.filter(game => {
          const gameDate = new Date(game.date);
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(game.patch);
          const isDateGood = gameDate >= startDate && gameDate <= endDate;
          return isPatchGood && isDateGood;
        });
        setSoloQData(data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }, [name, filterPatch, startDate, endDate]);

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
    setEndDate(selectedDate);
  };

  const handlePatchChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

return (
  <div>
    <div className='filters'>
      Starting Date
      <input
        type="date"
        value={startDate?.toISOString().split('T')[0]}
        onChange={handleStartDateChange}
        />
      Ending Date
      <input
        type="date"
        value={endDate?.toISOString().split('T')[0]}
        onChange={handleEndDateChange}
        />
      Patch
      <div className='short-chooser'>
        <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
      </div>
    </div>
    <div style={{width: "100%", display: 'flex'}}>

      <SoloqPageComponent key={name} name={name} filterPatch={filterPatch} startDate={startDate} endDate={endDate}/>
      <div style={{width: "80%"}} >
        {soloQData.length > 0 && soloQData.sort((a, b) => new Date(b.date) - new Date(a.date)).map((game) => {
          return (
            <div style={{width: "100%", backgroundColor: 'lightgray', borderRadius: "10px", display: 'flex', padding: "10px", marginTop: "10px", alignItems: 'center'}} key={game.gameId}>
              <div style={{width: "20%", textAlign: 'center'}}>
                <div>
                  {new Date(game.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric'})}
                </div>

                <div>
                  {new Date(game.date).toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric' })}
                </div>
              </div>

              <div style={{width: "10%"}}>
                {game.patch}
              </div>
              <div style={{width: "50%", display:'flex', justifyContent: 'space-evenly'}}>
                <div>
                  <img className="small-image" src={championPhotos[game.champion_name]} alt=""/>
                  {game.champion_name}
                </div>
                VS
                <div>
                  <img className="small-image" src={championPhotos[game.enemy_champion_name]} alt=""/>
                  {game.enemy_champion_name}
                </div>
              </div>
              <div style={{width: "20%"}}>
                {game.kills}/{game.deaths}/{game.assists}

              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);
}


export default PlayerSoloqPageComponent;