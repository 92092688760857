import React, { useState } from 'react';
import './Player.css';
import mapPhotsDictionary from './MapData';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';

const WardMapComponent = ({ wardData }) => {
    const [timeRange, setTimeRange] = useState([0, 60]);
    const [selectedTeam, setSelectedTeam] = useState('any');
    const [selectedWard, setSelectedWard] = useState('any');

    const handleTimeRangeChange = (newTimeRange) => {
      setTimeRange(newTimeRange);
    };

    const mapWidth = 500;
    const mapHeight = 500;
    const minX = -120;
    const minY = -120;
    const maxX = 14970;
    const maxY = 14980;

    const captureScreenshot = (table) => {
      const element = document.getElementById(table);

      html2canvas(element)
        .then((canvas) => {
          const image = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'screenshot.png';
          link.href = image;
          link.click();
        })
        .catch(err => {
          console.error('Error capturing screenshot', err);
        });
    };

    const translateCoordinates = (x, y) => {
      const newX = ((x - minX) / (maxX - minX)) * mapWidth;
      const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
      return { newX, newY };
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <div id="heatmap-container" style={{ position: 'relative' }}>
          <img style={{ position: 'relative' }} src={mapPhotsDictionary["SummonersRift"]} alt="" />
          {wardData && wardData.filter(ward => {
            const isWithinTimeRange = ward.placeTime >= timeRange[0] * 60 && ward.placeTime <= timeRange[1] * 60;
            const isMatchingTeam = selectedTeam === 'any' || ward.side === selectedTeam;
            const isMatchingWard = selectedWard === 'any' || ward.type === selectedWard;
            return isWithinTimeRange && isMatchingTeam && isMatchingWard;
          }).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-2 + 'px',
                        left: newX-2 + 'px',
                        width: '4px',
                        height: '4px',
                        backgroundColor: position.side,
                        borderRadius: '50%',
                        color: 'white'
                        }}
                    >
                        {/*Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')*/}
                    </div>
                );
                }
            )}
                </div>
        <button className="screenshot-button" onClick={() => captureScreenshot('heatmap-container')}>ScreenShot</button>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
          <div>
            <label htmlFor="teamSelect">Select side:</label>
            <select id="teamSelect" value={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
              <option value="any">Any</option>
              <option value="blue">Blue</option>
              <option value="red">Red</option>
            </select>
          </div>
          <div>
            <label htmlFor="teamSelect1">Ward type:</label>
            <select id="teamSelect1" value={selectedWard} onChange={e => setSelectedWard(e.target.value)}>
              <option value="any">Any</option>
              <option value="control">Control</option>
              <option value="yellowTrinket">Yellow</option>
              <option value="sight">Blue</option>
            </select>
          </div>
          <span>{timeRange[0]} - {timeRange[1]}</span>
          <Slider
            range
            min={0}
            max={60}
            step={1}
            value={timeRange}
            onChange={handleTimeRangeChange}
            style={{
              width: '100%',
              padding: '10px',
              background: 'linear-gradient(to right, #FF5733, #FF5733)',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          />
        </div>
      </div>
    );
  };

  export default WardMapComponent;
