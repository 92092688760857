import React, { useState, useMemo } from 'react';
import { championPhotos, championData } from './ChampionsIcon';
import roleIcon from './Role';
import { Pie } from 'react-chartjs-2';

const ChampionRoleStats = ({ filteredMatches }) => {
  const [selectedChampion, setSelectedChampion] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  const roleOptions = [
    { value: 0, label: 'Top' },
    { value: 1, label: 'Jgl' },
    { value: 2, label: 'Mid' },
    { value: 3, label: 'Bot' },
    { value: 4, label: 'Sup' },
  ];

  const handleChampionChange = (event) => {
    setSelectedChampion(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const formattedChampionOptions = Object.entries(championData).map(([id, champion]) => ({
    value: id,
    label: champion,
  })).sort((a, b) => a.label.localeCompare(b.label));

  const formattedRoleOptions = Object.entries(roleIcon).map(([id, role]) => ({
    value: id,
    label: id,
  })).sort((a, b) => a.label.localeCompare(b.label));

  const processMatches = (matches, championId, role) => {
    const opponentFrequency = { blind: {}, counter: {} };
    let blindPickCount = 0;
    let counterPickCount = 0;
    const teammateFrequencyByRole = {
      0: {},
      1: {},
      2: {},
      3: {},
      4: {},
    };

    matches.forEach((match) => {
      const matchChampions = [
        match.BP1, match.BP2, match.BP3, match.BP4, match.BP5,
        match.RP1, match.RP2, match.RP3, match.RP4, match.RP5,
      ].filter(Boolean);

      const championIndex = matchChampions.indexOf(Number(championId));
      if (championIndex === -1) return;

      const side = championIndex < 5 ? 'B' : 'R';
      const position = championIndex < 5 ? championIndex + 1 : championIndex - 4;

      if (match[`${side}${position}R`] !== role) return;

      const isBlindPick = match[`${side}${position}BC`] === 'B';

      if (isBlindPick) blindPickCount++;
      else counterPickCount++;

      const opponentChampions = side === 'B'
        ? [match.RP1, match.RP2, match.RP3, match.RP4, match.RP5]
        : [match.BP1, match.BP2, match.BP3, match.BP4, match.BP5];

      const opponentRoles = side === 'B'
        ? [match.R1R, match.R2R, match.R3R, match.R4R, match.R5R]
        : [match.B1R, match.B2R, match.B3R, match.B4R, match.B5R];

      const target = isBlindPick ? opponentFrequency.blind : opponentFrequency.counter;

      opponentChampions.forEach((opponentChampion, index) => {
        if (opponentRoles[index] === role) {  // Ensure opponent has the same role
          if (!target[opponentChampion]) target[opponentChampion] = { count: 0, wins: 0 };
          target[opponentChampion].count += 1;
          if (match.winner === (side === 'B' ? 100 : 200)) target[opponentChampion].wins += 1;
        }
      });

      const teammateChampions = side === 'B'
        ? [match.BP1, match.BP2, match.BP3, match.BP4, match.BP5]
        : [match.RP1, match.RP2, match.RP3, match.RP4, match.RP5];

      const teammateRoles = side === 'B'
        ? [match.B1R, match.B2R, match.B3R, match.B4R, match.B5R]
        : [match.R1R, match.R2R, match.R3R, match.R4R, match.R5R];

      teammateChampions.forEach((teammateChampion, index) => {
        const teammateRole = teammateRoles[index];
        if (teammateRole !== role && teammateChampion !== Number(championId)) {
          if (!teammateFrequencyByRole[teammateRole]) {
            teammateFrequencyByRole[teammateRole] = {};
          }
          if (!teammateFrequencyByRole[teammateRole][teammateChampion]) {
            teammateFrequencyByRole[teammateRole][teammateChampion] = { count: 0, wins: 0 };
          }
          teammateFrequencyByRole[teammateRole][teammateChampion].count += 1;
          if ((match.winner === 100 && side === 'B') || (match.winner === 200 && side === 'R')) {
            teammateFrequencyByRole[teammateRole][teammateChampion].wins += 1;
          }
        }
      });
    });

    const calculateTopStats = (frequencyMap) => {
      return Object.entries(frequencyMap)
        .map(([championId, stats]) => ({
          championId,
          pickRate: stats.count,
          winRate: stats.wins / stats.count,
        }))
        .sort((a, b) => b.pickRate - a.pickRate)
        .slice(0, 10);
    };

    const topTeammatesByRole = Object.entries(teammateFrequencyByRole).reduce((result, [role, champions]) => {
      result[role] = calculateTopStats(champions);
      return result;
    }, {});

    return {
      topBlindOpponents: calculateTopStats(opponentFrequency.blind),
      topCounterOpponents: calculateTopStats(opponentFrequency.counter),
      topTeammatesByRole,
      blindPickCount,
      counterPickCount,
    };
  };

  const {
    topBlindOpponents,
    topCounterOpponents,
    topTeammatesByRole,
    blindPickCount,
    counterPickCount,
  } = useMemo(() => {
    if (selectedChampion && selectedRole) {
      return processMatches(filteredMatches, selectedChampion, Number(selectedRole));
    }
    return { topBlindOpponents: [], topCounterOpponents: [], topTeammatesByRole: [], blindPickCount: 0,  counterPickCount: 0};
  }, [filteredMatches, selectedChampion, selectedRole]);

  const getRedGreenCellno0 = (value, minValue, maxValue) => {
    if (value === (minValue + maxValue) / 2) {
      return `hsl(0, 0%, 100%)`;
    } else {
      const midpoint = (minValue + maxValue) / 2;
      const normalizedValue =
        value > midpoint
          ? (value - midpoint) / (maxValue - midpoint)
          : (-value + midpoint) / (minValue + midpoint);
      const hue = value > midpoint ? 120 : 0;
      const lightness = 100 - 50 * normalizedValue;
      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  const calculateWinRate = (matches, championId, role) => {
    let totalGames = 0;
    let wins = 0;

    matches.forEach((match) => {
      const matchChampions = [
        match.BP1, match.BP2, match.BP3, match.BP4, match.BP5,
        match.RP1, match.RP2, match.RP3, match.RP4, match.RP5,
      ];

      const championIndex = matchChampions.indexOf(Number(championId));
      if (championIndex === -1) return;

      const side = championIndex < 5 ? 'B' : 'R';
      const position = championIndex < 5 ? championIndex + 1 : championIndex - 4;

      if (match[`${side}${position}R`] !== role) return;

      totalGames += 1;
      if (match.winner === (side === 'B' ? 100 : 200)) {
        wins += 1;
      }
    });

    return totalGames > 0 ? (wins / totalGames) * 100 : 0;
  };

  const data = {
    labels: ['Blind Pick', 'Counter Pick'],
    datasets: [
      {
        data: [blindPickCount, counterPickCount],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const championWinRate = useMemo(() => {
    if (selectedChampion && selectedRole) {
      return calculateWinRate(filteredMatches, selectedChampion, Number(selectedRole));
    }
    return 0;
  }, [filteredMatches, selectedChampion, selectedRole]);

  return (
    <div>
      <div style={{display: 'flex'}}>

        <div style={{width: "30%", padding: "5px", backgroundColor: "white"}}>

          <h3>Champion Role Stats</h3>
          <div style={{width: "100%"}}>
            <select style={{width: "40%"}} onChange={handleChampionChange} value={selectedChampion}>
              <option value="">Champion</option>
              {formattedChampionOptions.map(champion => (
                <option key={champion.value} value={champion.value}>
                  {champion.label}
                </option>
              ))}
            </select>
          </div>
          <div style={{width: "100%"}}>
            <select  style={{width: "40%"}} onChange={handleRoleChange} value={selectedRole}>
              <option value="">Role</option>
              {roleOptions.map(role => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div style={{width: "70%", display: 'flex', justifyContent: 'center'}}>
          <img style={{width: "100px", height: "100px"}} src={championPhotos[championData[selectedChampion]]} alt=''/>
          <img style={{width: "100px", height: "100px"}} src={roleIcon[selectedRole]} alt=''/>
          <div style={{display: 'flex', flexWrap: 'wrap', width: "70%"}}>
            <div style={{width: "60%"}}>

            <h1 style={{color: getRedGreenCellno0(championWinRate, 0, 100)}}>WR: {championWinRate.toFixed(0)}%</h1>
            <p style={{color: 'white'}}>Blind   Pick: {blindPickCount}</p>
            <p style={{color: 'white'}}>Counter Pick: {counterPickCount}</p>
            </div>
            <div style={{width: "40%"}}>
              <Pie data={data} />
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', width: "100%", flexWrap: "wrap" }}>
        {selectedChampion && selectedRole && (
          <>
            <div style={{ width: '50%', padding: "10px" }}>
              <h3 style={{textAlign: 'center', color: 'white'}}>{championData[selectedChampion]} Blind Pick</h3>
              <div style={{backgroundColor: "white"}}>
                <table style={{ width: '100%', borderCollapse: 'collapse', padding: "3px" }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid black', padding: '4px' }}>Opponent</th>
                      <th style={{ border: '1px solid black', padding: '4px' }}>Pick Rate</th>
                      <th style={{ border: '1px solid black', padding: '4px' }}>{championData[selectedChampion]} Win Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topBlindOpponents.map(opponent => (
                      <tr key={opponent.championId}>
                        <td style={{ border: '1px solid black', padding: '4px' }}>
                          <img src={championPhotos[championData[opponent.championId]]} alt='' className='small-image'/>
                          {championData[opponent.championId]}
                        </td>
                        <td style={{ border: '1px solid black', padding: '4px' }}>{opponent.pickRate}</td>
                        <td style={{ border: '1px solid black', padding: '4px', backgroundColor: getRedGreenCellno0(opponent.winRate * 100, 0, 100) }}>{(opponent.winRate * 100).toFixed(0)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ width: '50%', padding: "10px" }}>
              <h3 style={{textAlign: 'center', color: 'white'}} >{championData[selectedChampion]} Counter Pick</h3>
              <table style={{ width: '100%', borderCollapse: 'collapse', padding: "3px", backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid black', padding: '4px' }}>Opponent</th>
                    <th style={{ border: '1px solid black', padding: '4px' }}>Pick Rate</th>
                    <th style={{ border: '1px solid black', padding: '4px' }}>{championData[selectedChampion]} Win Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {topCounterOpponents.map(opponent => (
                    <tr key={opponent.championId}>
                      <td style={{ border: '1px solid black', padding: '4px' }}>
                        <img src={championPhotos[championData[opponent.championId]]} alt='' className='small-image'/>
                        {championData[opponent.championId]}
                      </td>
                      <td style={{ border: '1px solid black', padding: '4px' }}>{opponent.pickRate}</td>
                      <td style={{ border: '1px solid black', padding: '4px', backgroundColor: getRedGreenCellno0(opponent.winRate * 100, 0, 100)  }}>{(opponent.winRate * 100).toFixed(0)}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ width: '100%' }}>
              <h3 style={{textAlign: 'center', color: 'white'}}>Common Teammates</h3>
              <div style={{ display: "flex", flexWrap: 'wrap' }}>
                {Object.entries(topTeammatesByRole).map(([role, teammates]) => (
                  role !== roleIcon[selectedRole] && teammates.length > 0 && ( // Skip selected role and ensure there are teammates
                    <div key={role} style={{ width: "25%", padding: '3px', color: 'white' }}>

                      <img src={roleIcon[role]} alt='' className='small-image'/>
                        {roleOptions[role].label}
                      <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: "white", color: 'black' }}>
                        <thead>
                          <tr>
                            <th style={{ border: '1px solid black', padding: '4px' }}>Champ</th>
                            <th style={{ border: '1px solid black', padding: '4px' }}>Pick</th>
                            <th style={{ border: '1px solid black', padding: '4px' }}>WR%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teammates.map(teammate => (
                            <tr key={teammate.championId}>
                              <td style={{ border: '1px solid black', padding: '4px' }}>
                                <img src={championPhotos[championData[teammate.championId]]} alt='' className='small-image'/>
                                {championData[teammate.championId]}
                              </td>
                              <td style={{ border: '1px solid black', padding: '4px' }}>{teammate.pickRate}</td>
                              <td style={{ border: '1px solid black', padding: '4px', backgroundColor: getRedGreenCellno0(teammate.winRate * 100, 0, 100)  }}>{(teammate.winRate * 100).toFixed(0)}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

};

export default ChampionRoleStats;
