import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Player.css';
import {championPhotos} from './ChampionsIcon';
import CustomSelect from './CustomSelect';
import runeIconDictionary from './RuneIcons';
import itemIconDictionary from './ItemIcons';
import axiosInstance from './backend/axiosInstance';
import { getGreenCellColor, getRedGreenCell, getRedGreenCellno0, getRedCellColor } from './base/colorUtils';

const ChampionComponent = () => {
  const { champion } = useParams();
  document.title = champion;
  const [hideLowGames, setHideLowGames] = useState(true);

  const encodedChampionName = encodeURIComponent(champion);
  const [matchups, setMatchups] = useState([]);
  const [filteredMatchups, setFilteredMatchups] = useState([]);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [selectedLeague, setSelectedLeague] = useState([]);
  const [filterLeague, setFilterLeague] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);

  useEffect(() => {
    axiosInstance
    .get(`players/?championName=${encodedChampionName}`)
    .then(response => {
      // Apply the patch filter to the fetched data
      const filteredMatchups = filterPatch.length > 0
      ? response.data.filter(matchup => filterPatch.includes(matchup.patch))
      : response.data;
    setMatchups(filteredMatchups);

    const uniquePatches = [...new Set( response.data.map((match) => match.patch))];
    const uniqueLeagues = [...new Set( response.data.map((match) => match.leagueName))];
    const filteredLeagues = uniqueLeagues.filter((league) => league !== null);
    const formattedLeagueOptions = filteredLeagues.sort().map((league) => ({
      value: league,
      label: league,
    }));

    const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
      value: patch,
      label: patch,
    }));

    setLeagueOption(formattedLeagueOptions);
    setPatchOption(formattedPatchOptions);

    })
    .catch(error => {
      console.error(error);
    });


    // Fetch unique league options
  }, [filterPatch, encodedChampionName]);

  const handlePatchChange = selectedOptions => {
    const leagueValues = selectedOptions.map(option => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleLeagueChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterLeague(leagueValues);
    setSelectedLeague(selectedOptions);
  };

  useEffect(() => {

    const filteredMatchups = matchups.filter(matchup =>
      (filterPatch.length === 0 || filterPatch.includes(matchup.patch)) &&
      (filterLeague.length === 0 || filterLeague.includes(matchup.leagueName))
    );

    setFilteredMatchups(filteredMatchups);
  }, [filterPatch, filterLeague, matchups]);

  return (
    <div>
      {filteredMatchups && (
        <div style={{backgroundColor: "black"}}>
          <div className='filters'>
              Patch
              <div className='short-chooser'>
                <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
              </div>
              League
              <div className='league-chooser'>
                <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
              </div>

              {"Hide Games < 10"}
              <label>
                <input
                  type="checkbox"
                  checked={hideLowGames}
                  onChange={() => setHideLowGames(!hideLowGames)}
                />
              </label>
          </div>
          <h2 style={{color: 'white'}}>Matchups for {champion}</h2>
          <MatchupTable matchups={filteredMatchups}  hideLowGames={hideLowGames} champion={champion}/>
        </div>
      )}
    <div style={{height: '300px', backgroundColor: "black", width: "100%"}}>

    </div>
    </div>
  );
};

const calculateAverages = (matchups) => {
  const totalGames = matchups.length;
  const totalWins = matchups.filter((matchup) => matchup.winner === 1).length;
  const winRate = (totalWins / totalGames) * 100;

  const sumField = (matchups, field) => {
    return matchups.reduce((sum, matchup) => sum + matchup[field], 0);
  };

  const averageGameDuration = sumField(matchups, 'gameDuration') / totalGames;
  const averageKillsAndAssists8 = sumField(matchups, 'killsAndAssists8') / totalGames;
  const averageDeaths8 = sumField(matchups, 'deaths8') / totalGames;
  const averageGoldDiff8 = sumField(matchups, 'goldDiff8') / totalGames;
  const averageCSDiff8 = sumField(matchups, 'csDiff8') / totalGames;
  const averageExpDiff8 = sumField(matchups, 'expDiff8') / totalGames;
  const averageKillsAndAssists14 = sumField(matchups, 'killsAndAssists14') / totalGames;
  const averageDeaths14 = sumField(matchups, 'deaths14') / totalGames;
  const averageGoldDiff14 = sumField(matchups, 'goldDiff14') / totalGames;
  const averageCSDiff14 = sumField(matchups, 'csDiff14') / totalGames;
  const averageExpDiff14 = sumField(matchups, 'expDiff14') / totalGames;
  const averageDamageShared = sumField(matchups, 'damageShared') / totalGames;
  const averageGoldShared = sumField(matchups, 'goldShared') / totalGames;
  const averageJungleProximity = sumField(matchups, 'JungleProximity') / totalGames;
  const averageDamageDealt = sumField(matchups, 'DamageDealt') / totalGames/ averageGameDuration;
  const averageCS = sumField(matchups, 'CS') / totalGames / averageGameDuration;

  return {
    totalGames,
    winRate,
    averageGameDuration,
    averageKillsAndAssists8,
    averageDeaths8,
    averageGoldDiff8,
    averageCSDiff8,
    averageExpDiff8,
    averageKillsAndAssists14,
    averageDeaths14,
    averageGoldDiff14,
    averageCSDiff14,
    averageExpDiff14,
    averageDamageShared,
    averageGoldShared,
    averageJungleProximity,
    averageDamageDealt,
    averageCS,
  };
};

const MatchupTable = ({ matchups, hideLowGames, champion }) => {
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc'); // Initial sorting order
  const [expandedRows, setExpandedRows] = useState({});
  const [runeStatistics, setRuneStatistics] = useState({});
  const [itemStatistics, setItemStatistics] = useState({});

  useEffect(() => {
    const calculateRuneStatistics = (matchups) => {
      const calculatedRuneStatistics = {
        runes0to3: {},
        runes4to5: {},
      };

      matchups.forEach((matchup) => {
        const { enemyChampionName, rune0, rune1, rune2, rune3, rune4, rune5 } = matchup;

        if (!calculatedRuneStatistics.runes0to3[enemyChampionName]) {
          calculatedRuneStatistics.runes0to3[enemyChampionName] = {};
        }
        if (!calculatedRuneStatistics.runes4to5[enemyChampionName]) {
          calculatedRuneStatistics.runes4to5[enemyChampionName] = {};
        }

        [rune0, rune1, rune2, rune3].forEach((rune) => {
          if (!calculatedRuneStatistics.runes0to3[enemyChampionName][rune]) {
            calculatedRuneStatistics.runes0to3[enemyChampionName][rune] = {
              amount: 0,
              wr: 0,
            };
          }

          calculatedRuneStatistics.runes0to3[enemyChampionName][rune].amount += 1;

          if (matchup.winner === 1) {
            calculatedRuneStatistics.runes0to3[enemyChampionName][rune].wr += 1;
          }
        });

        [rune4, rune5].forEach((rune) => {
          if (!calculatedRuneStatistics.runes4to5[enemyChampionName][rune]) {
            calculatedRuneStatistics.runes4to5[enemyChampionName][rune] = {
              amount: 0,
              wr: 0,
            };
          }

          calculatedRuneStatistics.runes4to5[enemyChampionName][rune].amount += 1;

          if (matchup.winner === 1) {
            calculatedRuneStatistics.runes4to5[enemyChampionName][rune].wr += 1;
          }
        });
      });

      // Calculate win rate for each rune for each enemy champion for runes 0-3 and runes 4-5
      ['runes0to3', 'runes4to5'].forEach((runeSet) => {
        Object.keys(calculatedRuneStatistics[runeSet]).forEach((enemyChampionName) => {
          const runesForChampion = calculatedRuneStatistics[runeSet][enemyChampionName];
          Object.keys(runesForChampion).forEach((rune) => {
            const stats = runesForChampion[rune];
            stats.wr = stats.amount > 0 ? stats.wr / stats.amount : 0;
          });
        });
      });

      setRuneStatistics(calculatedRuneStatistics);
    };

    calculateRuneStatistics(matchups);
  }, [matchups]);

  useEffect(() => {
    const calculateItemStatistics = (matchups) => {
      const calculatedItemStatistics = {};

      matchups.forEach((matchup) => {
        const { enemyChampionName, item0, item1, item2, item3, item4, item5 } = matchup;

        if (!calculatedItemStatistics[enemyChampionName]) {
          calculatedItemStatistics[enemyChampionName] = {
            items: [],
          };
        }

        const items = [item0, item1, item2, item3, item4, item5];

        items.forEach((item) => {
          if (item !== 0) {
            const itemInfo = calculatedItemStatistics[enemyChampionName].items.find(
              (itemInfo) => itemInfo.item === item
            );

            if (itemInfo) {
              itemInfo.picked += 1;
              if (matchup.winner === 1) {
                itemInfo.won += 1;
              }
            } else {
              calculatedItemStatistics[enemyChampionName].items.push({
                item,
                picked: 1,
                won: matchup.winner === 1 ? 1 : 0,
              });
            }
          }
        });
      });

      // Calculate the overall win rate for each item for each enemy champion
      Object.keys(calculatedItemStatistics).forEach((enemyChampionName) => {
        calculatedItemStatistics[enemyChampionName].items.forEach((itemInfo) => {
          itemInfo.wr = itemInfo.picked > 0 ? itemInfo.won / itemInfo.picked : 0;
        });

        // Sort the items by the number of picks in descending order
        calculatedItemStatistics[enemyChampionName].items.sort(
          (a, b) => b.picked - a.picked
        );
      });

      setItemStatistics(calculatedItemStatistics);
    };

    calculateItemStatistics(matchups);
  }, [matchups]);


  const performanceDictionary = {};

  matchups.forEach((matchup) => {
    const enemyChampionName = matchup.enemyChampionName;
    // Check if 'matchup.players' is defined and is an array

      if (!performanceDictionary[enemyChampionName]) {
        performanceDictionary[enemyChampionName] = [];
      }
      matchup["performance"] = matchup.goldDiff14 + matchup.expDiff14;
        performanceDictionary[enemyChampionName].push(matchup);

  });
  // Sort the performance data for each enemy champion
  Object.keys(performanceDictionary).forEach((enemyChampionName) => {
    performanceDictionary[enemyChampionName].sort((a, b) => b.performance - a.performance);
  });

  console.log(performanceDictionary)

  const toggleExpandedRow = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSort = (field) => {
    if (sortField === field) {
      toggleSortOrder();
    } else {
      setSortField(field);
      setSortOrder('asc'); // Set to ascending order if it's a new field
    }
  }
  const averagesByEnemyChampion = {};

  matchups.forEach((matchup) => {
    const { enemyChampionName } = matchup;

    // If the enemy champion does not exist in the averages object, initialize it
    if (!averagesByEnemyChampion[enemyChampionName]) {
      averagesByEnemyChampion[enemyChampionName] = [];
    }

    averagesByEnemyChampion[enemyChampionName].push(matchup);
  });

  // Calculate averages for each enemy champion
  Object.keys(averagesByEnemyChampion).forEach((enemyChampion) => {
    const matchups = averagesByEnemyChampion[enemyChampion];
    averagesByEnemyChampion[enemyChampion] = calculateAverages(matchups);
  });

  // Sort the enemies based on the selected field
  const sortedEnemies = Object.entries(averagesByEnemyChampion).map(([enemyChampion, averages]) => {
    return { enemyChampion, averages };
  }).sort((a, b) => {
    if (sortField) {
      const valueA = a.averages[sortField];
      const valueB = b.averages[sortField];

      if (sortOrder === 'asc') {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    } else {
      return b.averages.totalGames - a.averages.totalGames;
    }
  });

  const handleGameClick = (teamName) => {
    window.location.href = `/match/${teamName}`;
  };

  const getMaxValue = (property) => {
    return Math.max(...sortedEnemies.map((player) => player.averages[property])).toFixed(2);
  };

  const getMinValue = (property) => {
    return Math.min(...sortedEnemies.map((player) => player.averages[property])).toFixed(2);
  };

  const maxAverageCS = getMaxValue('averageCS');
  const minAverageCS = getMinValue('averageCS');

  const maxAverageCSDiff8 = getMaxValue('averageCSDiff8');
  const minAverageCSDiff8 = getMinValue('averageCSDiff8');

  const maxAverageCSDiff14 = getMaxValue('averageCSDiff14');
  const minAverageCSDiff14 = getMinValue('averageCSDiff14');

  const maxAverageDamageDealt = getMaxValue('averageDamageDealt');
  const minAverageDamageDealt = getMinValue('averageDamageDealt');

  const maxAverageDamageShared = getMaxValue('averageDamageShared');
  const minAverageDamageShared = getMinValue('averageDamageShared');

  const maxAverageDeaths8 = getMaxValue('averageDeaths8');
  //const minAverageDeaths8 = getMinValue('averageDeaths8');

  const maxAverageDeaths14 = getMaxValue('averageDeaths14');
  //const minAverageDeaths14 = getMinValue('averageDeaths14');

  const maxAverageExpDiff8 = getMaxValue('averageExpDiff8');
  const minAverageExpDiff8 = getMinValue('averageExpDiff8');

  const maxAverageExpDiff14 = getMaxValue('averageExpDiff14');
  const minAverageExpDiff14 = getMinValue('averageExpDiff14');

  const maxAverageGoldDiff8 = getMaxValue('averageGoldDiff8');
  const minAverageGoldDiff8 = getMinValue('averageGoldDiff8');

  const maxAverageGoldDiff14 = getMaxValue('averageGoldDiff14');
  const minAverageGoldDiff14 = getMinValue('averageGoldDiff14');

  const maxAverageGoldShared = getMaxValue('averageGoldShared');
  const minAverageGoldShared = getMinValue('averageGoldShared');

  const maxAverageJungleProximity = getMaxValue('averageJungleProximity');
  const minAverageJungleProximity = getMinValue('averageJungleProximity');

  const maxAverageKillsAndAssists8 = getMaxValue('averageKillsAndAssists8');
  //const minAverageKillsAndAssists8 = getMinValue('averageKillsAndAssists8');

  const maxAverageKillsAndAssists14 = getMaxValue('averageKillsAndAssists14');
  //const minAverageKillsAndAssists14 = getMinValue('averageKillsAndAssists14');

  //const maxTotalGames = getMaxValue('totalGames');
  // const minTotalGames = getMinValue('totalGames');

  //const maxWinRate = getMaxValue('winRate');
  //const minWinRate = getMinValue('winRate');



  console.log(minAverageDamageDealt, maxAverageDamageDealt)
  console.log(minAverageCS, maxAverageCS)
  return (
    <table style={{ textAlign: 'center', color: 'black', backgroundColor: 'whitesmoke', padding: "5px" }}>
      <thead>
        <tr>
          <th onClick={() => handleSort(null)}>Versus</th>
          <th onClick={() => handleSort('totalGames')}>GAMES</th>
          <th onClick={() => handleSort('winRate')}>WR%</th>
          <th onClick={() => handleSort('averageKillsAndAssists8')}>K+A@8</th>
          <th onClick={() => handleSort('averageDeaths8')}>D@8</th>
          <th onClick={() => handleSort('averageGoldDiff8')}>GD@8</th>
          <th onClick={() => handleSort('averageCSDiff8')}>CSD@8</th>
          <th onClick={() => handleSort('averageExpDiff8')}>XPD@8</th>
          <th onClick={() => handleSort('averageKillsAndAssists14')}>K+A@14</th>
          <th onClick={() => handleSort('averageDeaths14')}>D@14</th>
          <th onClick={() => handleSort('averageGoldDiff14')}>GD@14</th>
          <th onClick={() => handleSort('averageCSDiff14')}>CSD@14</th>
          <th onClick={() => handleSort('averageExpDiff14')}>XPD@14</th>
          <th onClick={() => handleSort('averageDamageShared')}>DMG%</th>
          <th onClick={() => handleSort('averageGoldShared')}>G%</th>
          <th onClick={() => handleSort('averageJungleProximity')}>JGL%</th>
          <th onClick={() => handleSort('averageDamageDealt')}>DMG/M</th>
          <th onClick={() => handleSort('averageCS')}>CS/M</th>

        </tr>
      </thead>
      <tbody>
      {sortedEnemies?.map(({ enemyChampion, averages }) => {
        const { totalGames, winRate, averageKillsAndAssists8, averageDeaths8, averageGoldDiff8, averageCSDiff8, averageExpDiff8, averageKillsAndAssists14, averageDeaths14, averageGoldDiff14, averageCSDiff14, averageExpDiff14, averageDamageShared, averageGoldShared, averageJungleProximity, averageDamageDealt, averageCS } = averages;

        if (hideLowGames && totalGames < 10) {
          return null; // Hide the row
        }

        return (
          <>

          <tr key={enemyChampion}>
            <td style={{ textAlign: 'left' }}>
              <img src={championPhotos[champion]} alt='' className='small-image' />
              vs
              <img src={championPhotos[enemyChampion]} alt='' className='small-image' />
              {enemyChampion}
            </td>
            <td>{totalGames}</td>
            <td style={{ backgroundColor: getRedGreenCellno0(winRate.toFixed(2), 0, 100) }}>{`${winRate.toFixed(1)}%`}</td>
            <td style={{ backgroundColor: getGreenCellColor(averageKillsAndAssists8.toFixed(2), maxAverageKillsAndAssists8) }}>{averageKillsAndAssists8.toFixed(2)}</td>
            <td style={{ backgroundColor: getRedCellColor(averageDeaths8.toFixed(2), maxAverageDeaths8) }}>{averageDeaths8.toFixed(2)}</td>
            <td style={{ backgroundColor: getRedGreenCell(averageGoldDiff8.toFixed(2), minAverageGoldDiff8, maxAverageGoldDiff8)}}>{averageGoldDiff8.toFixed(0)}</td>
            <td style={{ backgroundColor: getRedGreenCell(averageCSDiff8.toFixed(2), minAverageCSDiff8, maxAverageCSDiff8)}}>{averageCSDiff8.toFixed(1)}</td>
            <td style={{ backgroundColor: getRedGreenCell(averageExpDiff8.toFixed(2), minAverageExpDiff8, maxAverageExpDiff8)}}>{averageExpDiff8.toFixed(0)}</td>
            <td style={{ backgroundColor: getGreenCellColor(averageKillsAndAssists14.toFixed(2), maxAverageKillsAndAssists14) }}>{averageKillsAndAssists14.toFixed(2)}</td>
            <td style={{ backgroundColor: getRedCellColor(averageDeaths14.toFixed(2), maxAverageDeaths14) }}>{averageDeaths14.toFixed(2)}</td>
            <td style={{ backgroundColor: getRedGreenCell(averageGoldDiff14.toFixed(2), minAverageGoldDiff14, maxAverageGoldDiff14)}}>{averageGoldDiff14.toFixed(0)}</td>
            <td style={{ backgroundColor: getRedGreenCell(averageCSDiff14.toFixed(2), minAverageCSDiff14, maxAverageCSDiff14)}}>{averageCSDiff14.toFixed(1)}</td>
            <td style={{ backgroundColor: getRedGreenCell(averageExpDiff14.toFixed(2), minAverageExpDiff14, maxAverageExpDiff14)}}>{averageExpDiff14.toFixed(0)}</td>
            <td style={{ backgroundColor: getRedGreenCellno0((100*averageDamageShared).toFixed(2), minAverageDamageShared*100, maxAverageDamageShared*100)}}>{(averageDamageShared * 100).toFixed(2)}%</td>
            <td style={{ backgroundColor: getRedGreenCellno0((100*averageGoldShared).toFixed(2), minAverageGoldShared*100, maxAverageGoldShared*100)}}>{(averageGoldShared * 100).toFixed(2)}%</td>
            <td style={{ backgroundColor: getRedGreenCellno0((100*averageJungleProximity).toFixed(2), minAverageJungleProximity*100, maxAverageJungleProximity*100)}}>{(averageJungleProximity * 100).toFixed(2)}%</td>
            <td style={{ backgroundColor: getRedGreenCellno0(averageDamageDealt.toFixed(0), minAverageDamageDealt, maxAverageDamageDealt)}}>{(averageDamageDealt).toFixed(0)}</td>
            <td style={{ backgroundColor: getRedGreenCellno0(averageCS.toFixed(2), minAverageCS, maxAverageCS)}}>{(averageCS).toFixed(2)}</td>
            <td>
              <button onClick={() => toggleExpandedRow(enemyChampion)}>
                {expandedRows[enemyChampion] ? "↑" : "↓"}
              </button>
            </td>
            {/* Add more table cells for other data points */}
          </tr>

          {expandedRows[enemyChampion] && (
            <>
              <tr style={{ width: "100%" }}>
                <td colSpan={19} style={{ width: "100%" }}>
                    Primary runes
                  <div style={{display: "flex", textAlign: "center"}}>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8112"]} alt='8112' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8112] ? runeStatistics["runes0to3"][enemyChampion][8112]["amount"] : ""} {" "}
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8112] ? (runeStatistics["runes0to3"][enemyChampion][8112]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8124"]} alt='8124' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8124] ? runeStatistics["runes0to3"][enemyChampion][8124]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8124] ? (runeStatistics["runes0to3"][enemyChampion][8124]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </th>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8128"]} alt='8128' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8128] ? runeStatistics["runes0to3"][enemyChampion][8128]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8128] ? (runeStatistics["runes0to3"][enemyChampion][8128]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </th>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9923"]} alt='9923' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][9923] ? runeStatistics["runes0to3"][enemyChampion][9923]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][9923] ? (runeStatistics["runes0to3"][enemyChampion][9923]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8126"]} alt='8126' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8126] ? runeStatistics["runes0to3"][enemyChampion][8126]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8126] ? (runeStatistics["runes0to3"][enemyChampion][8126]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8139"]} alt='8139' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8139] ? runeStatistics["runes0to3"][enemyChampion][8139]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8139] ? (runeStatistics["runes0to3"][enemyChampion][8139]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8143"]} alt='8143' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8143] ? runeStatistics["runes0to3"][enemyChampion][8143]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8143] ? (runeStatistics["runes0to3"][enemyChampion][8143]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8136"]} alt='8136' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8136] ? runeStatistics["runes0to3"][enemyChampion][8136]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8136] ? (runeStatistics["runes0to3"][enemyChampion][8136]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8120"]} alt='8120' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8120] ? runeStatistics["runes0to3"][enemyChampion][8120]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8120] ? (runeStatistics["runes0to3"][enemyChampion][8120]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8138"]} alt='8138' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8138] ? runeStatistics["runes0to3"][enemyChampion][8138]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8138] ? (runeStatistics["runes0to3"][enemyChampion][8138]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8135"]} alt='8135' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8135] ? runeStatistics["runes0to3"][enemyChampion][8135]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8135] ? (runeStatistics["runes0to3"][enemyChampion][8135]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8134"]} alt='8134' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8134] ? runeStatistics["runes0to3"][enemyChampion][8134]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8134] ? (runeStatistics["runes0to3"][enemyChampion][8134]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8105"]} alt='8105' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8105] ? runeStatistics["runes0to3"][enemyChampion][8105]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8105] ? (runeStatistics["runes0to3"][enemyChampion][8105]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8106"]} alt='8106' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8106] ? runeStatistics["runes0to3"][enemyChampion][8106]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8106] ? (runeStatistics["runes0to3"][enemyChampion][8106]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8214"]} alt='8214' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8214] ? runeStatistics["runes0to3"][enemyChampion][8214]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8214] ? (runeStatistics["runes0to3"][enemyChampion][8214]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8229"]} alt='8229' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8229] ? runeStatistics["runes0to3"][enemyChampion][8229]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8229] ? (runeStatistics["runes0to3"][enemyChampion][8229]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8230"]} alt='8230' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8230] ? runeStatistics["runes0to3"][enemyChampion][8230]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8230] ? (runeStatistics["runes0to3"][enemyChampion][8230]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8224"]} alt='8224' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8224] ? runeStatistics["runes0to3"][enemyChampion][8224]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8224] ? (runeStatistics["runes0to3"][enemyChampion][8224]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8226"]} alt='8226' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8226] ? runeStatistics["runes0to3"][enemyChampion][8226]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8226] ? (runeStatistics["runes0to3"][enemyChampion][8226]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8275"]} alt='8275' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8275] ? runeStatistics["runes0to3"][enemyChampion][8275]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8275] ? (runeStatistics["runes0to3"][enemyChampion][8275]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8210"]} alt='8210' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8210] ? runeStatistics["runes0to3"][enemyChampion][8210]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8210] ? (runeStatistics["runes0to3"][enemyChampion][8210]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8234"]} alt='8234' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8234] ? runeStatistics["runes0to3"][enemyChampion][8234]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8234] ? (runeStatistics["runes0to3"][enemyChampion][8234]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8233"]} alt='8233' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8233] ? runeStatistics["runes0to3"][enemyChampion][8233]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8233] ? (runeStatistics["runes0to3"][enemyChampion][8233]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8237"]} alt='8237' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8237] ? runeStatistics["runes0to3"][enemyChampion][8237]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8237] ? (runeStatistics["runes0to3"][enemyChampion][8237]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8232"]} alt='8232' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8232] ? runeStatistics["runes0to3"][enemyChampion][8232]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8232] ? (runeStatistics["runes0to3"][enemyChampion][8232]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8236"]} alt='8236' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8236] ? runeStatistics["runes0to3"][enemyChampion][8236]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8236] ? (runeStatistics["runes0to3"][enemyChampion][8236]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8437"]} alt='8437' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8437] ? runeStatistics["runes0to3"][enemyChampion][8437]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8437] ? (runeStatistics["runes0to3"][enemyChampion][8437]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8439"]} alt='8439' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8439] ? runeStatistics["runes0to3"][enemyChampion][8439]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8439] ? (runeStatistics["runes0to3"][enemyChampion][8439]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8465"]} alt='8465' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8465] ? runeStatistics["runes0to3"][enemyChampion][8465]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8465] ? (runeStatistics["runes0to3"][enemyChampion][8465]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8446"]} alt='8446' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8446] ? runeStatistics["runes0to3"][enemyChampion][8446]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8446] ? (runeStatistics["runes0to3"][enemyChampion][8446]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8463"]} alt='8463' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8463] ? runeStatistics["runes0to3"][enemyChampion][8463]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8463] ? (runeStatistics["runes0to3"][enemyChampion][8463]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8401"]} alt='8401' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8401] ? runeStatistics["runes0to3"][enemyChampion][8401]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8401] ? (runeStatistics["runes0to3"][enemyChampion][8401]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8429"]} alt='8429' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8429] ? runeStatistics["runes0to3"][enemyChampion][8429]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8429] ? (runeStatistics["runes0to3"][enemyChampion][8429]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8444"]} alt='8444' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8444] ? runeStatistics["runes0to3"][enemyChampion][8444]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8444] ? (runeStatistics["runes0to3"][enemyChampion][8444]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8473"]} alt='8473' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8473] ? runeStatistics["runes0to3"][enemyChampion][8473]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8473] ? (runeStatistics["runes0to3"][enemyChampion][8473]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8451"]} alt='8451' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8451] ? runeStatistics["runes0to3"][enemyChampion][8451]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8451] ? (runeStatistics["runes0to3"][enemyChampion][8451]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8453"]} alt='8453' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8453] ? runeStatistics["runes0to3"][enemyChampion][8453]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8453] ? (runeStatistics["runes0to3"][enemyChampion][8453]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8242"]} alt='8242' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8242] ? runeStatistics["runes0to3"][enemyChampion][8242]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8242] ? (runeStatistics["runes0to3"][enemyChampion][8242]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8351"]} alt='8351' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8351] ? runeStatistics["runes0to3"][enemyChampion][8351]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8351] ? (runeStatistics["runes0to3"][enemyChampion][8351]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8360"]} alt='8360' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8360] ? runeStatistics["runes0to3"][enemyChampion][8360]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8360] ? (runeStatistics["runes0to3"][enemyChampion][8360]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8369"]} alt='8369' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8369] ? runeStatistics["runes0to3"][enemyChampion][8369]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8369] ? (runeStatistics["runes0to3"][enemyChampion][8369]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8306"]} alt='8306' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8306] ? runeStatistics["runes0to3"][enemyChampion][8306]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8306] ? (runeStatistics["runes0to3"][enemyChampion][8306]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8304"]} alt='8304' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8304] ? runeStatistics["runes0to3"][enemyChampion][8304]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8304] ? (runeStatistics["runes0to3"][enemyChampion][8304]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8313"]} alt='8313' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8313] ? runeStatistics["runes0to3"][enemyChampion][8313]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8313] ? (runeStatistics["runes0to3"][enemyChampion][8313]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8321"]} alt='8321' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8321] ? runeStatistics["runes0to3"][enemyChampion][8321]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8321] ? (runeStatistics["runes0to3"][enemyChampion][8321]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8316"]} alt='8316' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8316] ? runeStatistics["runes0to3"][enemyChampion][8316]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8316] ? (runeStatistics["runes0to3"][enemyChampion][8316]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8345"]} alt='8345' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8345] ? runeStatistics["runes0to3"][enemyChampion][8345]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8345] ? (runeStatistics["runes0to3"][enemyChampion][8345]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8347"]} alt='8347' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8347] ? runeStatistics["runes0to3"][enemyChampion][8347]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8347] ? (runeStatistics["runes0to3"][enemyChampion][8347]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8410"]} alt='8410' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8410] ? runeStatistics["runes0to3"][enemyChampion][8410]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8410] ? (runeStatistics["runes0to3"][enemyChampion][8410]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8352"]} alt='8352' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8352] ? runeStatistics["runes0to3"][enemyChampion][8352]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion] && runeStatistics["runes0to3"][enemyChampion][8352] ? (runeStatistics["runes0to3"][enemyChampion][8352]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8005"]} alt='8005' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8005] ? runeStatistics["runes0to3"][enemyChampion][8005]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8005] ? (runeStatistics["runes0to3"][enemyChampion][8005]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8008"]} alt='8008' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8008] ? runeStatistics["runes0to3"][enemyChampion][8008]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8008] ? (runeStatistics["runes0to3"][enemyChampion][8008]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8021"]} alt='8021' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8021] ? runeStatistics["runes0to3"][enemyChampion][8021]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8021] ? (runeStatistics["runes0to3"][enemyChampion][8021]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                          <th colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8010"]} alt='8010' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8010] ? runeStatistics["runes0to3"][enemyChampion][8010]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8010] ? (runeStatistics["runes0to3"][enemyChampion][8010]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9101"]} alt='9101' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][9101] ? runeStatistics["runes0to3"][enemyChampion][9101]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][9101] ? (runeStatistics["runes0to3"][enemyChampion][9101]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9111"]} alt='9111' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][9111] ? runeStatistics["runes0to3"][enemyChampion][9111]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][9111] ? (runeStatistics["runes0to3"][enemyChampion][9111]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8009"]} alt='8009' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8009] ? runeStatistics["runes0to3"][enemyChampion][8009]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8009] ? (runeStatistics["runes0to3"][enemyChampion][8009]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9104"]} alt='9104' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][9104] ? runeStatistics["runes0to3"][enemyChampion][9104]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][9104] ? (runeStatistics["runes0to3"][enemyChampion][9104]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9105"]} alt='9105' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][9105] ? runeStatistics["runes0to3"][enemyChampion][9105]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][9105] ? (runeStatistics["runes0to3"][enemyChampion][9105]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9103"]} alt='9103' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][9103] ? runeStatistics["runes0to3"][enemyChampion][9103]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][9103] ? (runeStatistics["runes0to3"][enemyChampion][9103]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8014"]} alt='8014' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8014] ? runeStatistics["runes0to3"][enemyChampion][8014]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8014] ? (runeStatistics["runes0to3"][enemyChampion][8014]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8017"]} alt='8017' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8017] ? runeStatistics["runes0to3"][enemyChampion][8017]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8017] ? (runeStatistics["runes0to3"][enemyChampion][8017]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8299"]} alt='8299' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes0to3"][enemyChampion][8299] ? runeStatistics["runes0to3"][enemyChampion][8299]["amount"] : ""}
                              {" "}{runeStatistics["runes0to3"][enemyChampion][8299] ? (runeStatistics["runes0to3"][enemyChampion][8299]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td colSpan={19} style={{ width: "100%" }}>
                    Secondary
                  <div style={{display: "flex", textAlign: 'center'}}>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8126"]} alt='8126' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8126] ? runeStatistics["runes4to5"][enemyChampion][8126]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8126] ? (runeStatistics["runes4to5"][enemyChampion][8126]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8139"]} alt='8139' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8139] ? runeStatistics["runes4to5"][enemyChampion][8139]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8139] ? (runeStatistics["runes4to5"][enemyChampion][8139]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8143"]} alt='8143' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8143] ? runeStatistics["runes4to5"][enemyChampion][8143]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8143] ? (runeStatistics["runes4to5"][enemyChampion][8143]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8136"]} alt='8136' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8136] ? runeStatistics["runes4to5"][enemyChampion][8136]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8136] ? (runeStatistics["runes4to5"][enemyChampion][8136]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8120"]} alt='8120' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8120] ? runeStatistics["runes4to5"][enemyChampion][8120]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8120] ? (runeStatistics["runes4to5"][enemyChampion][8120]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8138"]} alt='8138' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8138] ? runeStatistics["runes4to5"][enemyChampion][8138]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8138] ? (runeStatistics["runes4to5"][enemyChampion][8138]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8135"]} alt='8135' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8135] ? runeStatistics["runes4to5"][enemyChampion][8135]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8135] ? (runeStatistics["runes4to5"][enemyChampion][8135]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8134"]} alt='8134' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8134] ? runeStatistics["runes4to5"][enemyChampion][8134]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8134] ? (runeStatistics["runes4to5"][enemyChampion][8134]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8105"]} alt='8105' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8105] ? runeStatistics["runes4to5"][enemyChampion][8105]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8105] ? (runeStatistics["runes4to5"][enemyChampion][8105]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                          <td colSpan={3} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8106"]} alt='8106' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8106] ? runeStatistics["runes4to5"][enemyChampion][8106]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8106] ? (runeStatistics["runes4to5"][enemyChampion][8106]["wr"]*100).toFixed(0) : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8224"]} alt='8224' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8224] ? runeStatistics["runes4to5"][enemyChampion][8224]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8224] ? (runeStatistics["runes4to5"][enemyChampion][8224]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8226"]} alt='8226' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8226] ? runeStatistics["runes4to5"][enemyChampion][8226]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8226] ? (runeStatistics["runes4to5"][enemyChampion][8226]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8275"]} alt='8275' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8275] ? runeStatistics["runes4to5"][enemyChampion][8275]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8275] ? (runeStatistics["runes4to5"][enemyChampion][8275]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8210"]} alt='8210' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8210] ? runeStatistics["runes4to5"][enemyChampion][8210]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8210] ? (runeStatistics["runes4to5"][enemyChampion][8210]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8234"]} alt='8234' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8234] ? runeStatistics["runes4to5"][enemyChampion][8234]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8234] ? (runeStatistics["runes4to5"][enemyChampion][8234]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8233"]} alt='8233' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8233] ? runeStatistics["runes4to5"][enemyChampion][8233]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8233] ? (runeStatistics["runes4to5"][enemyChampion][8233]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8237"]} alt='8237' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8237] ? runeStatistics["runes4to5"][enemyChampion][8237]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8237] ? (runeStatistics["runes4to5"][enemyChampion][8237]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8232"]} alt='8232' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8232] ? runeStatistics["runes4to5"][enemyChampion][8232]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8232] ? (runeStatistics["runes4to5"][enemyChampion][8232]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8236"]} alt='8236' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8236] ? runeStatistics["runes4to5"][enemyChampion][8236]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8236] ? (runeStatistics["runes4to5"][enemyChampion][8236]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8446"]} alt='8446' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8446] ? runeStatistics["runes4to5"][enemyChampion][8446]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8446] ? (runeStatistics["runes4to5"][enemyChampion][8446]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8463"]} alt='8463' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8463] ? runeStatistics["runes4to5"][enemyChampion][8463]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8463] ? (runeStatistics["runes4to5"][enemyChampion][8463]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8401"]} alt='8401' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8401] ? runeStatistics["runes4to5"][enemyChampion][8401]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8401] ? (runeStatistics["runes4to5"][enemyChampion][8401]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8429"]} alt='8429' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8429] ? runeStatistics["runes4to5"][enemyChampion][8429]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8429] ? (runeStatistics["runes4to5"][enemyChampion][8429]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8444"]} alt='8444' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8444] ? runeStatistics["runes4to5"][enemyChampion][8444]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8444] ? (runeStatistics["runes4to5"][enemyChampion][8444]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8473"]} alt='8473' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8473] ? runeStatistics["runes4to5"][enemyChampion][8473]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8473] ? (runeStatistics["runes4to5"][enemyChampion][8473]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8451"]} alt='8451' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8451] ? runeStatistics["runes4to5"][enemyChampion][8451]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8451] ? (runeStatistics["runes4to5"][enemyChampion][8451]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8453"]} alt='8453' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8453] ? runeStatistics["runes4to5"][enemyChampion][8453]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8453] ? (runeStatistics["runes4to5"][enemyChampion][8453]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8242"]} alt='8242' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8242] ? runeStatistics["runes4to5"][enemyChampion][8242]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8242] ? (runeStatistics["runes4to5"][enemyChampion][8242]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8306"]} alt='8306' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8306] ? runeStatistics["runes4to5"][enemyChampion][8306]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8306] ? (runeStatistics["runes4to5"][enemyChampion][8306]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8304"]} alt='8304' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8304] ? runeStatistics["runes4to5"][enemyChampion][8304]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8304] ? (runeStatistics["runes4to5"][enemyChampion][8304]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8313"]} alt='8313' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8313] ? runeStatistics["runes4to5"][enemyChampion][8313]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8313] ? (runeStatistics["runes4to5"][enemyChampion][8313]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8321"]} alt='8321' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8321] ? runeStatistics["runes4to5"][enemyChampion][8321]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8321] ? (runeStatistics["runes4to5"][enemyChampion][8321]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8316"]} alt='8316' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8316] ? runeStatistics["runes4to5"][enemyChampion][8316]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8316] ? (runeStatistics["runes4to5"][enemyChampion][8316]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8345"]} alt='8345' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8345] ? runeStatistics["runes4to5"][enemyChampion][8345]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8345] ? (runeStatistics["runes4to5"][enemyChampion][8345]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8347"]} alt='8347' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8347] ? runeStatistics["runes4to5"][enemyChampion][8347]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8347] ? (runeStatistics["runes4to5"][enemyChampion][8347]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8410"]} alt='8410' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8410] ? runeStatistics["runes4to5"][enemyChampion][8410]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8410] ? (runeStatistics["runes4to5"][enemyChampion][8410]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8352"]} alt='8352' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8352] ? runeStatistics["runes4to5"][enemyChampion][8352]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion] && runeStatistics["runes4to5"][enemyChampion][8352] ? (runeStatistics["runes4to5"][enemyChampion][8352]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9101"]} alt='9101' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][9101] ? runeStatistics["runes4to5"][enemyChampion][9101]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][9101] ? (runeStatistics["runes4to5"][enemyChampion][9101]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9111"]} alt='9111' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][9111] ? runeStatistics["runes4to5"][enemyChampion][9111]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][9111] ? (runeStatistics["runes4to5"][enemyChampion][9111]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8009"]} alt='8009' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8009] ? runeStatistics["runes4to5"][enemyChampion][8009]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8009] ? (runeStatistics["runes4to5"][enemyChampion][8009]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9104"]} alt='9104' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][9104] ? runeStatistics["runes4to5"][enemyChampion][9104]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][9104] ? (runeStatistics["runes4to5"][enemyChampion][9104]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9105"]} alt='9105' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][9105] ? runeStatistics["runes4to5"][enemyChampion][9105]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][9105] ? (runeStatistics["runes4to5"][enemyChampion][9105]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "33%" }}>
                            <img className="small-image" src={runeIconDictionary["rune9103"]} alt='9103' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][9103] ? runeStatistics["runes4to5"][enemyChampion][9103]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][9103] ? (runeStatistics["runes4to5"][enemyChampion][9103]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8014"]} alt='8014' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8014] ? runeStatistics["runes4to5"][enemyChampion][8014]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8014] ? (runeStatistics["runes4to5"][enemyChampion][8014]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8017"]} alt='8017' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8017] ? runeStatistics["runes4to5"][enemyChampion][8017]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8017] ? (runeStatistics["runes4to5"][enemyChampion][8017]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                          <td colSpan={4} style={{ width: "25%" }}>
                            <img className="small-image" src={runeIconDictionary["rune8299"]} alt='8299' />
                            <br />
                            <div style={{ fontSize: '12px', textAlign: 'center'}}>
                              {runeStatistics["runes4to5"][enemyChampion][8299] ? runeStatistics["runes4to5"][enemyChampion][8299]["amount"] : ""}
                              {" "}{runeStatistics["runes4to5"][enemyChampion][8299] ? (runeStatistics["runes4to5"][enemyChampion][8299]["wr"]*100).toFixed(0) + '%' : ""}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td colSpan={19} style={{ width: "100%" }}>
                  Items
                  <div style={{ display: "flex", textAlign: "center" }}>
                    {Object.keys(itemStatistics[enemyChampion]["items"]).map((item, index) => {
                      if (item !== "0") {
                        return (
                          <div key={index}>
                          <div>
                            <img src={itemIconDictionary["img" + itemStatistics[enemyChampion]["items"][item]["item"]]} alt={item} className='small-image' />
                          </div>
                          <div>
                            {itemStatistics[enemyChampion]["items"][item].picked}
                          </div>
                          <div style={{fontSize: "12px"}}>
                            {(100 * itemStatistics[enemyChampion]["items"][item].wr).toFixed(0)}%
                          </div>
                        </div>
                        );
                      }
                      return null; // Skip rendering for item 0
                    })}
                  </div>

                </td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td colSpan={19} style={{ width: "100%" }}>
                  "BEST" Players
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <table>
                      <thead>
                        <tr>
                          <th>name</th>
                          <th>league</th>
                          <th>goldDiff8</th>
                          <th>expDiff8</th>
                          <th>goldDiff14</th>
                          <th>expDiff14</th>
                          <th>Jungle Proximity</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>

                      {performanceDictionary[enemyChampion] && performanceDictionary[enemyChampion].slice(0, 5).map((player, index) => (
                        <tr key={index}>
                          <td>{player.summonerName}</td>
                          <td>{player.leagueName}</td>
                          <td>{player.goldDiff8}</td>
                          <td>{player.expDiff8}</td>
                          <td>{player.goldDiff14}</td>
                          <td>{player.expDiff14}</td>
                          <td>{(player.JungleProximity*100).toFixed(0)}%</td>
                          <td style={{ cursor: 'pointer' }} onClick={() => handleGameClick(player.scrimId)}>{"->"}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>



            </>

          )}
        </>
        );
      })}

      </tbody>
    </table>
  );
};





export default ChampionComponent;
