import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "./Match.css";
import { championPhotos} from "./ChampionsIcon";
import { Chart } from 'chart.js';
import itemIconDictionary from './ItemIcons';
import mapPhotsDictionary from './MapData';
import summonerSpellDictionary from './SummonerSpellData';
import soundFile  from '../sound/missing.mp3';
import axiosInstance from './backend/axiosInstance';

const MyChart = ({ data }) => {
  const chartRef = useRef(null);
  const [timePoint1Minutes, setTimePoint1Minutes] = useState(0);
  const [timePoint1Seconds, setTimePoint1Seconds] = useState(1);
  const [timePoint2Minutes, setTimePoint2Minutes] = useState(10);
  const [timePoint2Seconds, setTimePoint2Seconds] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState('gold'); // State to track whether to show gold or damage

  const convertToSeconds = (minutes, seconds) => {
    return minutes * 60 + seconds;
  };

  const calculateDifferences = () => {

    if (!data || !data.timeline) {
      console.error("Data or timeline is undefined.");
      return;
    }

    const timeInSeconds1 = convertToSeconds(timePoint1Minutes, timePoint1Seconds);
    const timeInSeconds2 = convertToSeconds(timePoint2Minutes, timePoint2Seconds);
    const getPlayerStats = (timePoint, playerName) => {
      for (const team in data.timeline[timePoint]) {
        if (data.timeline[timePoint][team][playerName]) {
          const playerStats = data.timeline[timePoint][team][playerName];
          return {
            totalGold: parseInt(playerStats.totalGold || 0),
            totalDamage: parseInt(playerStats.totalDamageDealtChampions || 0),
          };
        }
      }
      return { totalGold: 0, totalDamage: 0 };
    };

    const playerNames = Object.keys(data.timeline[timeInSeconds1.toString()]['one']).concat(Object.keys(data.timeline[timeInSeconds2.toString()]['two']));
    const newChartData = playerNames.map(playerName => {
      const stats1 = getPlayerStats(timeInSeconds1, playerName);
      const stats2 = getPlayerStats(timeInSeconds2, playerName);

      return {
        playerName,
        goldDiff: stats2.totalGold - stats1.totalGold,
        dmgDiff: stats2.totalDamage - stats1.totalDamage
      };
    });

    setChartData(newChartData);
  };

  useEffect(() => {
    const myChart = new Chart(chartRef.current, {
      type: 'bar',
      data: {
        labels: chartData.map(player => player.playerName),
        datasets: [{
          label: dataType === 'gold' ? 'Gold Difference' : 'Damage Difference',
          data: chartData.map(player => dataType === 'gold' ? player.goldDiff : player.dmgDiff),
          backgroundColor: dataType === 'gold' ? 'gold' : 'red'
        }]
      },
      options: {
        scales: {
          y: {  // Changed from yAxes to y
            beginAtZero: true,

          }
        }
      }
    });

    // Ensure to clean up and destroy the chart on component unmount
    return () => {
      myChart.destroy();
    };
  }, [chartData, dataType]);  // Dependency array includes dataType to re-run the effect when it changes


  return (
    <>
      <div style={{ width: "100%", justifyContent: 'center' }}>
        <input style={{width: "40px"}} type="number" value={timePoint1Minutes} onChange={e => setTimePoint1Minutes(Math.max(0, parseInt(e.target.value, 10)))} />
        <label>:</label>
        <input style={{width: "40px"}} type="number" value={timePoint1Seconds} max="59" onChange={e => setTimePoint1Seconds(Math.min(59, Math.max(0, parseInt(e.target.value, 10))))} />

        <label> - </label>

        {/* Time Point 2 Inputs */}
        <input style={{width: "40px"}} type="number" value={timePoint2Minutes} onChange={e => setTimePoint2Minutes(Math.max(0, parseInt(e.target.value, 10)))} />
        <label>:</label>
        <input style={{width: "40px"}} type="number" value={timePoint2Seconds} max="59" onChange={e => setTimePoint2Seconds(Math.min(59, Math.max(0, parseInt(e.target.value, 10))))} />

        <button onClick={calculateDifferences}>Calculate Differences</button>
        <select onChange={e => setDataType(e.target.value)} value={dataType}>
          <option value="gold">Gold</option>
          <option value="damage">Damage</option>
        </select>
      </div>
      <canvas style={{maxHeight: "200px"}} ref={chartRef}></canvas>
    </>
  );
};

const MapWithWards = ({ wardsData, currentTime, mapImage, matchData, gameTimeline, manualPositions, setManualPositions }) => {
  const [showWards, setShowWards] = useState(true);
  const [showProxmity, setShowProximity] = useState(true);
  const svgRef = useRef(null);
  const players = gameTimeline["timeline"][currentTime];

  const [counter, setCounter] = useState(0);

  const [lines, setLines] = useState([]); // Stores all lines
  const [currentLine, setCurrentLine] = useState([]); // Stores points for the current line being drawn
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#ffffff");

  const [placedIcons, setPlacedIcons] = useState({});
  const [isIconPanelVisible, setIsIconPanelVisible] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);

  const [fogOfWarVisible, setFogOfWarVisible] = useState(false);
  const [selectedFogColor, setSelectedFogColor] = useState("any");

  const map_icons = {
    "icon1" : mapPhotsDictionary["questionMark"],
    "icon2" : mapPhotsDictionary["swords"],
    "icon3" : mapPhotsDictionary["close"],
    "ward"  : mapPhotsDictionary["wardYes"],
    "ice"   : mapPhotsDictionary["ice"]
  }
  const mapBounds = {
    min: { x: -120, y: -120 },
    max: { x: 14870, y: 14980 },
  };

  const levelCircleStyle2 = {
    position: 'absolute',
    width: '15px', // Adjust the size of the level circle
    height: '15px', // Adjust the size of the level circle
    borderRadius: '50%', // Make the level circle circular
    backgroundColor: 'black', // Set the background color of the level circle to white
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const respawnTimerStyle = {
    display: "flex",
    color: "red",
    fontSize: "20px",
    position: "relative",
    top: "5px",
    fontWeight: "bold",
    left: '-30px', // Adjust this value to position the respawn timer slightly to the left
  };

  const playSound = () => {
    const sound = new Audio(soundFile ); // Replace with the path to your sound file
    sound.volume = 0.01; // Set the volume to half of the maximum volume
    sound.play();
  };

  const viewportHeight = window.innerHeight; // Gets the viewport height in pixels
  const mapHeight = viewportHeight * 0.94;
  const wardHeight = mapHeight * 900 / 15000;
  const mapImageDimensions = {
    width: mapHeight, // Adjust to the actual width of your map image
    height: mapHeight, // Adjust to the actual height of your map image
  };

  const scale = Math.min(
    mapImageDimensions.width / (mapBounds.max.x - mapBounds.min.x),
    mapImageDimensions.height / (mapBounds.max.y - mapBounds.min.y)
  );

  // Calculate whether each ward should be visible based on placement and kill times
  const visibleWards = wardsData.filter((ward) => {
    return ward.placeTime <= currentTime && (!ward.killTime || ward.killTime >= currentTime);
  });

  const imageStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  };

  const calculateTotalStat = (team, stat) => {
    return Object.values(team).reduce((totalGold, player) => {
        // Ensure currentGold is a number before adding it to the total
      return totalGold + (parseInt(player[stat]));
    }, 0);
  }

  const handleDragStart = (e, identifier, type) => {
    const dragData = { id: identifier, type };
    e.dataTransfer.setData('application/json', JSON.stringify(dragData));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const { id, type } = JSON.parse(e.dataTransfer.getData('application/json'));
    const mapRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - mapRect.left;
    const y = e.clientY - mapRect.top;
    const gameX = (x / scale) + mapBounds.min.x;
    const gameY = ((mapImageDimensions.height - y) / scale) + mapBounds.min.y;

    if (type === 'icon') {
        setPlacedIcons(prevPositions => ({
          ...prevPositions,
          [id]: {...prevPositions[id], x: gameX, y: gameY }
        }));
    } else if (type === 'champion') {
        setManualPositions(prevPositions => ({
        ...prevPositions,
        [id]: { x: gameX, y: gameY }
      }));
    }
};

const isPlayerVisible = (player, side) => {
  if (!fogOfWarVisible || selectedFogColor==="any" || selectedFogColor===side) {
    return true;
  }

  if (selectedFogColor!==side) {
    const turrets = gameTimeline["turrets"][`${selectedFogColor}_turrets`];
    console.log(`${selectedFogColor}_turrets`, turrets, turrets[0].time_killed)
    if (turrets) {
      for (const entity of Object.values(turrets)) {
        const distance = Math.sqrt(
          Math.pow(player.x - entity.position[0], 2) +
            Math.pow(player.y - entity.position[1], 2)
        );
        //console.log(`${selectedFogColor}_turrets`, player.championName, parseInt(distance))
        if (parseInt(distance) <=  1350 && entity.time_killed > currentTime) {
          return true;
        }

      }
    }

    if (visibleWards) {
      for (const entity of Object.values(visibleWards)) {
        const distance = Math.sqrt(
          Math.pow(player.x - entity.x, 2) +
          Math.pow(player.y - entity.y, 2)
        );
        const wardBackgroundColor = entity.teamName === matchData[0].team2 ? 'red' : 'blue';
        console.log(`${selectedFogColor}_wards`, player.championName, parseInt(distance), entity)
        if (parseInt(distance) <= 900 && side!==wardBackgroundColor) {
          return true;
        }
      }
    }
    const opponentPlayers = side === "red" ? players["one"] : players["two"];
    if (opponentPlayers) {
      for (const opponentPlayer of Object.values(opponentPlayers)) {
        const distance = Math.sqrt(
          Math.pow(player.x - opponentPlayer.x, 2) +
          Math.pow(player.y - opponentPlayer.y, 2)
        );
        if (parseInt(distance) <= 1350 && opponentPlayer.Alive) {
          return true;
        }
      }
    }
  }

  return false;
};

  let proximityPairs = [];
  if (showProxmity && players && players["one"]) {
    const playerPositions = Object.keys(players["one"]).map(playerName => {
      const player = players["one"][playerName];
      const manualPosition = manualPositions[playerName];
      const pos = manualPosition ? {
        x: (manualPosition.x - mapBounds.min.x) * scale ,
        y: (mapImageDimensions.height - (manualPosition.y - mapBounds.min.y) * scale)
      } :
      isPlayerVisible(player, player.side) ?
      {
        x: 30000,
        y: 30000,
      }
      : {
        x: (player.x - mapBounds.min.x) * scale+20,
        y: (mapImageDimensions.height - (player.y - mapBounds.min.y) * scale)+20,
      };
      return { ...pos, playerName };
    });

    for (let i = 0; i < playerPositions.length; i++) {
      for (let j = i + 1; j < playerPositions.length; j++) {
        const dx = playerPositions[i].x - playerPositions[j].x;
        const dy = playerPositions[i].y - playerPositions[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 100) { // Adjust this value based on your scale if needed
          proximityPairs.push([playerPositions[i], playerPositions[j]]);
        }
      }
    }
  }
  let proximityPairs2 = [];
  if (showProxmity && players && players["two"]) {
    const playerPositions = Object.keys(players["two"]).map(playerName => {
      const player = players["two"][playerName];
      const manualPosition = manualPositions[playerName];
      const pos = manualPosition ? {
        x: (manualPosition.x - mapBounds.min.x) * scale ,
        y: (mapImageDimensions.height - (manualPosition.y - mapBounds.min.y) * scale)
      } :
      isPlayerVisible(player, player.side) ?
      {
        x: 30000,
        y: 30000,
      }
      : {
        x: (player.x - mapBounds.min.x) * scale+20,
        y: (mapImageDimensions.height - (player.y - mapBounds.min.y) * scale)+20,
      };
      return { ...pos, playerName };
    });

    for (let i = 0; i < playerPositions.length; i++) {
      for (let j = i + 1; j < playerPositions.length; j++) {
        const dx = playerPositions[i].x - playerPositions[j].x;
        const dy = playerPositions[i].y - playerPositions[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 100) { // Adjust this value based on your scale if needed
          proximityPairs2.push([playerPositions[i], playerPositions[j]]);
        }
      }
    }
  }

  const handleMouseDown = (e) => {
    const svgRect = svgRef.current.getBoundingClientRect();
    if (e.button === 1 && selectedIcon && svgRef.current) { // Middle mouse button
      e.preventDefault();
      //const newX = e.clientX - svgRect.left - 20; // Adjust for the icon's offset
      //const newY = e.clientY - svgRect.top - 20;
      const mapRect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - mapRect.left;
      const y = e.clientY - mapRect.top;
      const gameX = (x / scale) + mapBounds.min.x;
      const gameY = ((mapImageDimensions.height - y) / scale) + mapBounds.min.y;
      const newIconId = `icon-${Date.now()}`
      if (selectedIcon === "icon1")
      {
        playSound();
      }
      const newIcon = {
          id: newIconId, // Example of using a timestamp for uniqueness
          icon: selectedIcon,
          x: gameX,
          y: gameY
      };
      setCounter(counter+1)
      // Update placedIcons with the new icon using its ID as the key
      setPlacedIcons(prevIcons => ({
        ...prevIcons,
        [newIcon.id]: newIcon
      }));
    }

    if (e.button === 2 && svgRef.current) { // Right mouse button
      const x = e.clientX - svgRect.left;
      const y = e.clientY - svgRect.top;

      setIsDrawing(true); // Begin drawing
      setCurrentLine([{ x, y }]);
    }
  };


  const handleMouseMove = (e) => {
    if (!isDrawing || !svgRef.current) return;

    const svgRect = svgRef.current.getBoundingClientRect();
    const x = e.clientX - svgRect.left; // Adjust X coordinate
    const y = e.clientY - svgRect.top;  // Adjust Y coordinate

    setCurrentLine(currentLine => [...currentLine, { x, y }]);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    if (currentLine.length > 1) {
      setLines(lines => [...lines, { points: currentLine, color: selectedColor }]);
      setCurrentLine([]);
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const pointsToSmoothPath = (points) => {
    if (points.length < 4) {
        // Not enough points to create a meaningful curve
        return `M ${points.map(p => `${p.x} ${p.y}`).join(' L ')}`;
    }

    // Start the path with the first point
    let d = `M ${points[0].x},${points[0].y}`;

    // Apply Euclidean interpolation to smooth the path while deleting every other point
    for (let i = 0; i < points.length - 1; i += 4) {
        const p0 = points[i];
        const p1 = points[i + 1];

        // Calculate the distance between the two points
        //const distance = Math.sqrt(Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2));

        // Add the intermediate point at 1/3 of the distance
        const qx = p0.x + (p1.x - p0.x) * (1 / 3);
        const qy = p0.y + (p1.y - p0.y) * (1 / 3);

        // Add the intermediate point to the path
        d += ` L ${qx},${qy}`;

        // Add the end point to the path
        d += ` L ${p1.x},${p1.y}`;
    }

    return d;
};

  const handleIconDelete = (id) => {
    setPlacedIcons(prevIcons => {
      const updatedIcons = { ...prevIcons };
      delete updatedIcons[id];
      return updatedIcons;
    });
  };

  // Function to delete a line
  const handleLineDelete = (index, event) => {
    event.stopPropagation();
    setLines(prevLines => {
      const updatedLines = [...prevLines];
      updatedLines.splice(index, 1);
      return updatedLines;
    });
  };

  const toggleIconPanelVisibility = () => setIsIconPanelVisible(prev => !prev);

  const handleIconSelection = (icon) => {
    setSelectedIcon(icon);
    setIsIconPanelVisible(false);
  };

  const itemsToShowLast = [3363, 3364, 3340, 3513];

  // Function to sort items, moving specific items to the end
  const sortItems = (items) => {
    return items.sort((a, b) => {
      const aIsSpecial = itemsToShowLast.includes(a);
      const bIsSpecial = itemsToShowLast.includes(b);

      if (aIsSpecial && !bIsSpecial) {
        return 1; // Move 'a' to the end
      } else if (!aIsSpecial && bIsSpecial) {
        return -1; // Keep 'a' before 'b'
      } else {
        return 0; // No change in order
      }
    });
  };

  const handleClearAll = () => {
    // Clear lines
    setLines([]);
    // Clear placed icons
    setPlacedIcons({});
  };

  const toggleFogOfWar = () => {
    setFogOfWarVisible(!fogOfWarVisible);
  };

  // Function to handle fog color selection
  const handleFogColorSelect = (color) => {
    setSelectedFogColor(color);
  };

  return (
    <div style={{width:"100%", height:"100%", justifyContent: 'space-between'}}>
      <div style={{width:"100%", backgroundColor: 'white'}}>
        <div style={{width:"100%", textAlign: 'center', display:'flex',  justifyContent: 'space-evenly'}}>
          <div>
          {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["one"], "kills") : "0" }
          /
          {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["one"], "deaths") : "0"}
          /
          {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["one"], "assists") : "0"}
          </div>
          <div>
          {gameTimeline.team1}
          {Math.floor(currentTime / 60)}:{String(currentTime % 60).padStart(2, '0')}
          {gameTimeline.team2}
          </div>
          <div>
          {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["two"], "kills") : "0" }
          /
          {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["two"], "deaths"): "0" }
          /
          {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["two"], "assists") : "0"}
          </div>
        </div>
      </div>
      <div style={{display: 'flex', width:"100%", height: "80%vh", justifyContent: 'space-between'}}>
        <div style={{display: 'flex', width: "50%", flexDirection: 'column', justifyContent: 'space-between'}}>
          <div>
            <button style={{width: "50px", height: "50px", display: 'flex', justifyContent: 'center'}} onClick={() => setShowWards(!showWards)}>{<img src={mapPhotsDictionary[showWards ? "wardYes": "wardNo"]} alt="" style={{width: "40px", height: "40px"}}/>}</button>
            <button style={{width: "50px", height: "50px", display: 'flex', justifyContent: 'center'}} onClick={() => setShowProximity(!showProxmity)}>{<img src={mapPhotsDictionary[showProxmity ? "proximityYes": "proximityNo"]} alt="" style={{width: "40px", height: "40px"}} />}</button>
            <input style={{width: "50px", height: "50px", display: 'flex', justifyContent: 'center'}}
              type="color"
              id="lineColor"
              value={selectedColor}
              onChange={(e) => setSelectedColor(e.target.value)}
              />
            <button style={{width: "50px", height: "50px", display: 'flex', justifyContent: 'center'}} onClick={toggleIconPanelVisibility}>
              <img src={mapPhotsDictionary["iconsYes"]} style={{width: "40px", height: "40px"}} alt="Toggle Icon Panel" />
            </button>
            {isIconPanelVisible && (
              <div style={{display: 'flex'}}>
                {Object.entries(map_icons).map(([iconKey, iconUrl]) => (
                  <button key={iconKey} onClick={() => handleIconSelection(iconKey)} style={{ width: "50px", height: "50px", display:'flex', justifyContent: 'center' }}>
                    <img src={iconUrl} style={{width: "40px", height: "40px"}} alt={iconKey} />
                  </button>
                ))}
              </div>
            )}
            <div>
              <button onClick={toggleFogOfWar}>Fog of War</button>
              {fogOfWarVisible && (
                <div>
                  <button key={'blue'} onClick={() => handleFogColorSelect('blue')}>Blue</button>
                  <button key={'any'}  onClick={() => handleFogColorSelect('any')}>any</button>
                  <button key={'red'} onClick={() => handleFogColorSelect('red')}>Red</button>
                </div>
              )}
            </div>
            <button key={"clear all"}onClick={handleClearAll}>Clear All</button>
          </div>
          <div style={{width:"100%", backgroundColor: '#ceefe9'}}>
            {players && players["one"] &&
            Object.keys(players["one"]).map((playerName, index) => {
              const player = players["one"][playerName]
              return (
                <div style={{flexDirection: 'row', height: '20%', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{width: "30%", display: "flex"}}>
                      <img style={{ width: '40px',height: '40px',borderRadius: '50%', filter: player.Alive ? 'none' : 'grayscale(100%)' }} src={championPhotos[player.championName]} alt={player.championName} />
                      <div style={levelCircleStyle2}>
                        <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                      </div>
                      <div style={respawnTimerStyle}>
                        {player.respawnTimer ? player.respawnTimer : ""}
                      </div>
                      <div style={{fontSize: "10px", justifyContent: "center", display: 'flex', flexDirection: 'column'}}>
                        {playerName}
                      </div>
                    </div>
                    <div>
                      <div>
                      {
                        sortItems(player.items).map((item, index) => (
                          <React.Fragment key={index}>
                            <img src={itemIconDictionary["img" + item]} style={{width: "25px", height: "25px"}} alt={item}/>
                          </React.Fragment>
                        ))
                      }
                      </div>
                      <div>
                        {player.kills}
                        {" / "}
                        {player.deaths}
                        {" / "}
                        {player.assists}
                        {"   "}
                        {player.CS}
                      </div>
                    </div>
                    <div style={{ width: "25px", backgroundColor: "black"}} >
                      <img src={summonerSpellDictionary[player.spell1_name]} style={{ height: "25px", filter:  player.spell1_cooldown === 0 ?  'none' : 'grayscale(100%)' }} alt={player.spell1_name}/>
                      <img src={summonerSpellDictionary[player.spell2_name]} style={{ height: "25px", filter:  player.spell2_cooldown === 0 ?  'none' : 'grayscale(100%)'}} alt={player.spell2_name}/>
                    </div>
                  </div>
                </div>

              )
              })
            }
          </div>
        </div>
        <div style={{ position: 'relative', width: `100%`, height: `100%` }}   onMouseDown={handleMouseDown}  onMouseMove={handleMouseMove}   onMouseUp={handleMouseUp}   onContextMenu={handleContextMenu} onDragOver={(e) => e.preventDefault()}   onDrop={(e) => { handleDrop(e);  }}>
          <img src={mapImage} alt="MAPA" style={{width: "100%", height: "100%", objectFit: 'contain'}} />
          {showWards && visibleWards.map((ward, index) => {
            // Calculate adjusted positions within map bounds and considering scale
            const wardBackgroundColor = ward.teamName === matchData[0].team2 ? 'red' : 'blue';
            const adjustedLeft = `${((( ward.x - mapBounds.min.x) * scale)-wardHeight-5).toFixed(2)}px`;
            const adjustedTop = `${(mapImageDimensions.height - ((ward.y - mapBounds.min.y) * scale)-wardHeight-5).toFixed(2)}px`;


            return (
              <div
              key={index}
              style={{
                position: 'absolute',
                left: adjustedLeft,
                top: adjustedTop,
                width: wardHeight*2, // Adjust size as needed
                height: wardHeight*2, // Adjust size as needed
                border: '2px solid ' + wardBackgroundColor, // Adjust border size and color
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000, // Ensure a higher z-index for wards
              }}
            >
                  <img src={mapPhotsDictionary["ward"]} alt='ward' style={{width: '10px', height: '10px', tintColor:wardBackgroundColor}} />
                </div>
                );
              })}
          {gameTimeline && gameTimeline["turrets"]["red_turrets"] &&
          Object.keys(gameTimeline["turrets"]["red_turrets"]).map((turret, index) => {
            const playerAdjustedLeft = `${(((gameTimeline["turrets"]["red_turrets"][index].position[0]- mapBounds.min.x) * scale) - 20).toFixed(2)}px`;
            const playerAdjustedTop = `${(mapImageDimensions.height - ((gameTimeline["turrets"]["red_turrets"][index].position[1] - mapBounds.min.y) * scale) - 20).toFixed(2)}px`;

            const playerStyle = {
              position: 'absolute',
              left: playerAdjustedLeft,
              top: playerAdjustedTop,
              zIndex: 1000, // Ensure a higher z-index for players,
            };

            return(
              <div key={index} style={playerStyle}>
                <img style={{imageStyle, opacity: gameTimeline["turrets"]["red_turrets"][index]["time_killed"] < currentTime && gameTimeline["turrets"]["red_turrets"][index]["time_killed"] != null  ? "0" : "1"}} src={mapPhotsDictionary["RedTurret"]} alt={"turret"} />
              </div>
            )
          })}
          {Object.entries(gameTimeline.jungle).map(([monsterType, positions]) =>
            Object.entries(positions).map(([positionKey, times], index) => {
              // Split the position string into x and y coordinates
              const [xPos, yPos] = positionKey.split(',').map(Number);

              // Calculate the adjusted left and top positions for CSS
              const monsterAdjustedLeft = `${(((xPos - mapBounds.min.x) * scale)).toFixed(2)}px`;
              const monsterAdjustedTop = `${(mapImageDimensions.height - ((yPos - mapBounds.min.y) * scale) - 10).toFixed(2)}px`;

              // Determine visibility based on the current time
              const isVisible = times.some(([startTime, endTime]) => currentTime >= startTime && currentTime <= endTime);

              const monsterStyle = {
                position: 'absolute',
                left: monsterAdjustedLeft,
                top: monsterAdjustedTop,
                display: isVisible ? 'block' : 'none', // Use the visibility determination to set CSS
                zIndex: 1000,
              };


              const circleStyle = {
                width: '20px', // Circle diameter
                height: '20px', // Circle diameter
                backgroundColor: 'orange',
                borderRadius: '50%', // Makes the div a circle
              };

              return isVisible ? (
                <div key={`${monsterType}-${positionKey}-${index}`} style={monsterStyle}>
                  <div style={circleStyle}></div>
                </div>
              ) : null;
            })
          )}

          {gameTimeline && gameTimeline["turrets"]["blue_turrets"] &&
          Object.keys(gameTimeline["turrets"]["blue_turrets"]).map((turret, index) => {
            const playerAdjustedLeft = `${(((gameTimeline["turrets"]["blue_turrets"][index].position[0]- mapBounds.min.x) * scale) - 20).toFixed(2)}px`;
            const playerAdjustedTop = `${(mapImageDimensions.height - ((gameTimeline["turrets"]["blue_turrets"][index].position[1] - mapBounds.min.y) * scale) - 20).toFixed(2)}px`;

            const playerStyle = {
              position: 'absolute',
              left: playerAdjustedLeft,
              top: playerAdjustedTop,
              zIndex: 1000, // Ensure a higher z-index for players,
            };

            return(
              <div key={index} style={playerStyle}>
                <img style={{imageStyle, opacity: gameTimeline["turrets"]["blue_turrets"][index]["time_killed"] < currentTime &&gameTimeline["turrets"]["blue_turrets"][index]["time_killed"] != null  ? "0" : "1"}} src={mapPhotsDictionary["BlueTurret"]} alt={"turret"} />
              </div>
            )
          })}

          {players && players["one"] &&
          Object.keys(players["one"]).map((playerName, index) => {
            const player = players["one"][playerName];
            let pos = manualPositions[playerName];
            if (!pos) {
              // Fall back to original logic if no manual position is available
              pos = {
                x: (player.x - mapBounds.min.x) * scale,
                y: (mapImageDimensions.height - (player.y - mapBounds.min.y) * scale)
              };
            } else {
              // Adjust manual position by scale if needed
              pos = {
                x: (pos.x - mapBounds.min.x) * scale - 20,
                y: (mapImageDimensions.height - (pos.y - mapBounds.min.y) * scale) - 20
              };
            }

            const baseWidth = 3; // Constant width
            const blackBackgroundHeight = 40;
            const healthHeight = ((player.health/100)) * blackBackgroundHeight;
            const experienceHeight = ((player.exp/100)) * blackBackgroundHeight;

            let topRightCircleContent;
            if (player.level >= 6) {
              const rCooldownAsInt = parseInt(player.rCooldown, 10); // Convert rCooldown to an integer
              if (rCooldownAsInt === 0) {
                // Display a green circle if level > 6 and rCooldown is 0
                topRightCircleContent = (
                  <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'green' }}></div>
                );
              } else {
                // Display the rCooldown value if it's not 0
                topRightCircleContent = (
                  <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'black', color: "white", fontSize: "10px" }}>
                    {rCooldownAsInt}
                  </div>
                );
              }
            }

            const playerStyle = {
              position: 'absolute',
              left: pos.x,
              top: pos.y,
              zIndex: 1000, // Ensure a higher z-index for players,
              filter: player.Alive ? 'none' : 'grayscale(100%)',
            };

            const blueFenceStyle = {
              width: '43px', // Adjust the width of the blue fence
              height: '43px', // Adjust the height of the blue fence
              borderRadius: '50%', // Make the blue fence circular
              backgroundColor: 'blue', // Set the background color of the blue fence to blue
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            };

            const levelCircleStyle = {
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '15px', // Adjust the size of the level circle
              height: '15px', // Adjust the size of the level circle
              borderRadius: '50%', // Make the level circle circular
              backgroundColor: 'black', // Set the background color of the level circle to white
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            };

            const healthBackgroundStyle = {
              position: 'absolute',
              left: `${baseWidth + 40}px`,
              width: `${baseWidth}px`, // Constant width
              height: `${blackBackgroundHeight}px`, // Constant height
              backgroundColor: 'black',
            };

            const healthBarStyle = {
              position: 'absolute',
              left: `${baseWidth + 40}px`, // Add 40 pixels to the right of the health bar
              width: `${baseWidth}px`, // Constant width
              height: `${healthHeight}px`, // Adjusted height based on health percentage
              backgroundColor: 'green',
              top: `${blackBackgroundHeight - healthHeight + 1}px`, // Position from the bottom
            };

            const ExperienceBackgroundStyle = {
              position: 'absolute',
              left: `${baseWidth + 43}px`,
              width: `${baseWidth}px`, // Constant width
              height: `${blackBackgroundHeight}px`, // Constant height
              backgroundColor: 'black',
            };

            const ExperienceStyle = {
              position: 'absolute',
              left: `${baseWidth + 43}px`, // Add 40 pixels to the right of the health bar
              width: `${baseWidth}px`, // Constant width
              height: `${experienceHeight}px`, // Adjusted height based on health percentage
              backgroundColor: 'purple',
              top: `${blackBackgroundHeight - experienceHeight + 1}px`, // Position from the bottom
            };

            return isPlayerVisible(player, "blue") ? (
              <div key={index} style={playerStyle}>
                <div style={blueFenceStyle}>
                  <img   draggable="true"  onDragStart={(e) => handleDragStart(e, playerName, 'champion')} style={imageStyle} src={championPhotos[player.championName]} alt={player.championName} />
                  <div style={levelCircleStyle}>
                    <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                  </div>
                  <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    {topRightCircleContent}
                  </div>
                  <div style={healthBackgroundStyle}></div>
                  <div style={healthBarStyle}></div>
                  <div style={ExperienceBackgroundStyle}></div>
                  <div style={ExperienceStyle}></div>
                </div>
              </div>
              ) : null;
          })}
          {players && players["two"] &&
          Object.keys(players["two"]).map((playerName, index) => {
            const player = players["two"][playerName];
            let pos = manualPositions[playerName];
            if (!pos) {
              // Fall back to original logic if no manual position is available
              pos = {
                x: (player.x - mapBounds.min.x) * scale,
                y: (mapImageDimensions.height - (player.y - mapBounds.min.y) * scale)
              };
            } else {
              // Adjust manual position by scale if needed
              pos = {
                x: (pos.x - mapBounds.min.x) * scale - 20,
                y: (mapImageDimensions.height - (pos.y - mapBounds.min.y) * scale) - 20
              };
            }

            const baseWidth = 3; // Constant width
            const blackBackgroundHeight = 40;
            const healthHeight = ((player.health/100)) * blackBackgroundHeight;
            const experienceHeight = ((player.exp/100)) * blackBackgroundHeight;

            let topRightCircleContent;
            if (player.level >= 6) {
              const rCooldownAsInt = parseInt(player.rCooldown, 10); // Convert rCooldown to an integer
              if (rCooldownAsInt === 0) {
                // Display a green circle if level > 6 and rCooldown is 0
                topRightCircleContent = (
                  <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'green' }}></div>
                );
              } else {
                // Display the rCooldown value if it's not 0
                topRightCircleContent = (
                  <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'black', color: "white", fontSize: "10px" }}>
                    {rCooldownAsInt}
                  </div>
                );
              }
            }

            const playerStyle = {
              position: 'absolute',
              left: pos.x,
              top: pos.y,
              zIndex: 1000, // Ensure a higher z-index for players,
              filter: player.Alive ? 'none' : 'grayscale(100%)',
            };

            const blueFenceStyle = {
              width: '43px', // Adjust the width of the blue fence
              height: '43px', // Adjust the height of the blue fence
              borderRadius: '50%', // Make the blue fence circular
              backgroundColor: 'red', // Set the background color of the blue fence to blue
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            };

            const levelCircleStyle = {
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '15px', // Adjust the size of the level circle
              height: '15px', // Adjust the size of the level circle
              borderRadius: '50%', // Make the level circle circular
              backgroundColor: 'black', // Set the background color of the level circle to white
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            };

            const healthBackgroundStyle = {
              position: 'absolute',
              left: `${baseWidth + 40}px`,
              width: `${baseWidth}px`, // Constant width
              height: `${blackBackgroundHeight}px`, // Constant height
              backgroundColor: 'black',
            };

            const healthBarStyle = {
              position: 'absolute',
              left: `${baseWidth + 40}px`, // Add 40 pixels to the right of the health bar
              width: `${baseWidth}px`, // Constant width
              height: `${healthHeight}px`, // Adjusted height based on health percentage
              backgroundColor: 'green',
              top: `${blackBackgroundHeight - healthHeight + 1}px`, // Position from the bottom
            };

            const ExperienceBackgroundStyle = {
              position: 'absolute',
              left: `${baseWidth + 43}px`,
              width: `${baseWidth}px`, // Constant width
              height: `${blackBackgroundHeight}px`, // Constant height
              backgroundColor: 'black',
            };

            const ExperienceStyle = {
              position: 'absolute',
              left: `${baseWidth + 43}px`, // Add 40 pixels to the right of the health bar
              width: `${baseWidth}px`, // Constant width
              height: `${experienceHeight}px`, // Adjusted height based on health percentage
              backgroundColor: 'purple',
              top: `${blackBackgroundHeight - experienceHeight + 1}px`, // Position from the bottom
            };

            return isPlayerVisible(player, "red") ? (
              <div key={index} style={playerStyle}>
              <div style={blueFenceStyle}>
                <img draggable="true" onDragStart={(e) => handleDragStart(e, playerName, 'champion')} style={imageStyle} src={championPhotos[player.championName]} alt={player.championName} />
                <div style={levelCircleStyle}>
                  <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                </div>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                  {topRightCircleContent}
                </div>
                <div style={healthBackgroundStyle}></div>
                <div style={healthBarStyle}></div>
                <div style={ExperienceBackgroundStyle}></div>
                <div style={ExperienceStyle}></div>
              </div>
            </div>
              ) : null;
          })}

          <svg ref={svgRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}  onDragOver={(e) => e.preventDefault()}>
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                <foreignObject
                  onClick={(event) => {
                    handleLineDelete(index, event);
                  }}
                  x={line.points[0].x} y={line.points[0].y} width="20" height="20">
                  <div
                    style={{color: "white", position: "relative", fontSize: "10px"}}
                  >
                    X
                  </div>
                </foreignObject>

                <defs>
                  <marker id={`arrowhead-${index}`} markerWidth="5" markerHeight="3.5"
                  refX="0" refY="1.75" orient="auto">
                    <polygon points="0 0, 5 1.75, 0 3.5" fill={line.color} />
                  </marker>
                </defs>
                <path
                  d={pointsToSmoothPath(line.points)}
                  stroke={line.color}
                  strokeWidth="4"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  fill="transparent"
                  markerEnd={`url(#arrowhead-${index})`}
                />
              </React.Fragment>
            ))}
            {currentLine.length > 1 && (
                <>
                <defs>
                  <marker id="arrowhead" markerWidth="5" markerHeight="3.5"
                  refX="0" refY="1.75" orient="auto">
                    <polygon points="0 0, 5 1.75, 0 3.5"  fill={selectedColor} />
                  </marker>
                </defs>
                <polyline
                  points={currentLine.map(p => `${p.x},${p.y}`).join(' ')}
                  stroke={selectedColor} // Use the currently selected color for the current line
                  strokeWidth="4"
                  fill="none"
                  markerEnd="url(#arrowhead)"
                />
              </>
            )}

            {showProxmity && proximityPairs.map(([player1, player2], index) => (
              <line key={index}
                  x1={player1.x}
                  y1={player1.y}
                  x2={player2.x}
                  y2={player2.y}
                  stroke="blue"
                  strokeWidth="2"
              />
            ))}

            {showProxmity && proximityPairs2.map(([player1, player2], index) => (
              <line key={index}
                  x1={player1.x}
                  y1={player1.y}
                  x2={player2.x}
                  y2={player2.y}
                  stroke="red"
                  strokeWidth="2"
              />
            ))}
          </svg>

          {Object.keys(placedIcons).map((key) => {
              const icon = placedIcons[key];
              return (
                  <React.Fragment key={icon.id}>
                      <img
                          src={map_icons[icon.icon]}
                          alt="icon"
                          draggable="true"
                          onDragStart={(e) => handleDragStart(e, icon.id, 'icon')}
                          style={{
                              width: "40px",
                              position: 'absolute',
                              left: (icon.x - mapBounds.min.x) * scale - 20,
                              top: (mapImageDimensions.height - (icon.y - mapBounds.min.y) * scale) - 20
                          }}
                      />
                      <div
                          style={{
                              color: "white",
                              fontSize: "10px",
                              position: 'absolute',
                              left: (icon.x - mapBounds.min.x) * scale + 30,
                              top: (mapImageDimensions.height - (icon.y - mapBounds.min.y) * scale - 40)
                          }}
                          onClick={() => handleIconDelete(icon.id)}
                      >
                          X
                      </div>
                  </React.Fragment>
              );
          })}

        </div>
        <div style={{width: "50%", display: 'flex', flexDirection: 'column-reverse'}}>
          <div style={{width:"100%", backgroundColor: "#f7dddd"}}>
            {players && players["two"] &&
            Object.keys(players["two"]).map((playerName, index) => {
              const player = players["two"][playerName]
              return (
                <div style={{flexDirection: 'row', height: '20%', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{width: "30%", display: "flex"}}>
                      <img style={{ width: '40px',height: '40px',borderRadius: '50%', filter: player.Alive ? 'none' : 'grayscale(100%)' }} src={championPhotos[player.championName]} alt={player.championName} />
                      <div style={levelCircleStyle2}>
                        <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                      </div>
                      <div style={respawnTimerStyle}>
                        {player.respawnTimer ? player.respawnTimer : ""}
                      </div>
                      <div style={{fontSize: "10px", justifyContent: "center", display: 'flex', flexDirection: 'column'}}>
                        {playerName}
                      </div>
                    </div>
                    <div>
                      <div>
                      {
                        sortItems(player.items).map((item, index) => (
                          <React.Fragment key={index}>
                            <img src={itemIconDictionary["img" + item]} style={{width: "25px", height: "25px"}} alt={item}/>
                          </React.Fragment>
                        ))
                      }
                      </div>
                      <div>
                        {player.kills}
                        {" / "}
                        {player.deaths}
                        {" / "}
                        {player.assists}
                        {"   "}
                        {player.CS}
                      </div>
                    </div>
                    <div style={{ width: "25px", backgroundColor: "black"}} >
                      <img src={summonerSpellDictionary[player.spell1_name]} style={{ height: "25px", filter:  player.spell1_cooldown === 0 ?  'none' : 'grayscale(100%)' }} alt={player.spell1_name}/>
                      <img src={summonerSpellDictionary[player.spell2_name]} style={{ height: "25px", filter:  player.spell2_cooldown === 0 ?  'none' : 'grayscale(100%)'}} alt={player.spell2_name}/>
                    </div>
                  </div>
                </div>

              )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
};

const MapScrimComponent = () => {

  const { gameId } = useParams();

  const [wardData, setWardData] = useState();
  const [gameTimeline, setGameTimeline] = useState();

  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(8.0);
  const [matchData, setMatchData] = useState();

  const [manualPositions, setManualPositions] = useState({});

  useEffect(() => {

    axiosInstance
      .get('parsedscrims/?gameId=' + gameId)
      .then((response) => {
        setMatchData(response.data);
        document.title = response.data[0]["team1"] + "-" + response.data[0]["team2"];
      })
      .catch((error) => {
        console.error('Error fetching match data:', error);
      });

      axiosInstance
      .get('playerwards/?gameId=' + gameId)
      .then((response) => {
        setWardData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching player data:', error);
      });

      axios
      .get('https://sanchidataportal.com/api2/api/official/' + gameId)
      .then((response) => {
        setGameTimeline(response.data);
      })
      .catch((error) => {
        console.error('Error fetching player data:', error);
      });
  }, [gameId]);

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setCurrentTime(newValue);
    setSliderValue(newValue);
  };

  function handleSpeedChange(event) {
    const newSpeed = parseFloat(event.target.value);
    setPlaybackSpeed(newSpeed);
  }

  const startTimer = () => {
    if (!isTimerRunning) {
      const intervalDuration = 1000 / playbackSpeed; // Adjust interval based on speed
      const newIntervalId = setInterval(() => {
        if (currentTime < matchData[0].gameDuration) {
          setCurrentTime((prevTime) => {
            const newTime = prevTime + 1;
            setSliderValue(newTime); // Synchronize slider with time
            return newTime;
          });
        } else {
          stopTimer();
        }
      }, intervalDuration); // Use the calculated interval duration
      setIsTimerRunning(true);
      setIntervalId(newIntervalId);
    }
    // No need to setSliderValue here as it's being updated in sync with currentTime
  };

  const stopTimer = () => {
    if (isTimerRunning) {
      clearInterval(intervalId);
      setIsTimerRunning(false);
    }
  };

  const toggleTimer = () => {
    if (isTimerRunning) {
      stopTimer();
    } else {
      startTimer();
      setManualPositions({});
    }
  };

  return (
    <div className='match-wards' style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
    {wardData && gameTimeline? (
        <>
      <MapWithWards currentTime={currentTime} wardsData={wardData} mapImage={mapPhotsDictionary["SummonersRift"]} matchData={matchData} gameTimeline={gameTimeline} manualPositions={manualPositions} setManualPositions={setManualPositions}/>
      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
        <button onClick={toggleTimer}>
          {isTimerRunning ? "⏸️ Pause" : "▶ Play"}
        </button>
        <input
          type="range"
          min="0"
          max={matchData[0].gameDuration}
          value={sliderValue}
          onChange={handleSliderChange}
          style={{width: "40%"}}
        />
        <div style={{ color: "white" }}>
          {Math.floor(currentTime / 60)}:{String(currentTime % 60).padStart(2, '0')}
        </div>
        <select onChange={handleSpeedChange} defaultValue="8.0">
          <option value="1.0">1x</option>
          <option value="4.0">4x</option>
          <option value="8.0">8x</option>
          <option value="16.0">16x</option>
        </select>
      </div>
      <div style={{width: "100%", justifyContent: "center", color: 'white', textAlign: 'center'}}>
            Charts Over time
            <MyChart data={gameTimeline}/>
      </div>
      </>
      ):(
        <>
        Loading...
        </>
      )}
    </div>
  );
};

export default MapScrimComponent;