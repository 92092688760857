import { championData, championPhotos } from './ChampionsIcon';
import React, { useEffect, useState } from 'react';
import CustomSelect from './CustomSelect';
import ChampionPoolComponnent from './ChampionPool';
import axiosInstance from './backend/axiosInstance';
import { getGreenCellColor, getRedGreenCellno0 } from './base/colorUtils';

const ScrimsChampionComponent = () => {

    const [selectedPatch, setSelectedPatch] = useState([]);
    const [filterPatch, setFilterPatch] = useState([]);
    const [patchOption, setPatchOption] = useState([]);

    const [championOccurrences, setChampionOccurrences] = useState({});
    const [matchData, setMatchData] = useState([]);

    const [playerData, setPlayerData] = useState([]);
    const [enemyPlayerData, setEnemyPlayerData] = useState([]);

    const [filteredPlayerChampionData, setFilteredPlayerChampionData] = useState([]);
    const [filteredEnemyChampionData, setFilteredEnemyChampionData] = useState([]);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    const [startDate, setStartDate] = useState(twoWeeksAgo);
    const [endDate, setEndDate] = useState(new Date());

    const [sortColumn, setSortColumn] = useState('championName'); // Default sort column
    const [sortDirection, setSortDirection] = useState('desc');

    useEffect(() => {
        axiosInstance
        .get('parsedscrims/?team='+localStorage.getItem("team"))
        .then((response) => {
          const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setMatchData(sortedMatchData); // Set the fetched data

        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

        axiosInstance
        .get('scrims_enemyteam_playerpeformance/?team='+localStorage.getItem("team"))
        .then((response) => {
            setEnemyPlayerData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

        axiosInstance
        .get('playersScrims/?team='+localStorage.getItem("team"))
        .then((response) => {
            setPlayerData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

        axiosInstance
          .get('unique_patches/')
          .then((response) => {
            const formattedPatchOptions = response.data
              .sort() // Sort alphabetically
              .map((league) => ({
                value: league,
                label: league,
              }));
            setPatchOption(formattedPatchOptions);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });

    }, []);

    useEffect(() => {
      const playerData1 = playerData.filter((player) => {
        const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
        const matchDate = new Date(player.date);
        return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) && isPatchGood;
      });

      const enemyPlayerData1 = enemyPlayerData.filter((player) => {
        const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
        const matchDate = new Date(player.date);
        return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) && isPatchGood;
      });

      const matchData1 = matchData.filter((player) => {
        const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
        const matchDate = new Date(player.date);
        return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) && isPatchGood;
      });

      setFilteredPlayerChampionData(playerData1)
      setFilteredEnemyChampionData(enemyPlayerData1)
      setChampionOccurrences(countChampionOccurrences(matchData1));
    }, [playerData, filterPatch, enemyPlayerData, matchData, startDate, endDate]);


    function countChampionOccurrences(matchData) {
        const championOccurrences = {};
        const wins = {}; // To store the wins for each champion
        Object.keys(championData).forEach(championId => {
          championOccurrences[championId] = { bans: 0, picks: 0 };
          wins[championId] = 0;
        });

        matchData.forEach(match => {
          for (let i = 1; i <= 5; i++) {
            const blueBanKey = `BB${i}`;
            const redBanKey = `RB${i}`;
            const bluePickKey = `BP${i}`;
            const redPickKey = `RP${i}`;

            const blueBanChampionId = match[blueBanKey];
            const redBanChampionId = match[redBanKey];
            const bluePickChampionId = match[bluePickKey];
            const redPickChampionId = match[redPickKey];

            // Process bans
            if (blueBanChampionId) {
              if (championOccurrences[blueBanChampionId]) {
                championOccurrences[blueBanChampionId].bans++;
              } else {
                championOccurrences[blueBanChampionId] = { bans: 1, picks: 0 };
              }
            }

            if (redBanChampionId) {
              if (championOccurrences[redBanChampionId]) {
                championOccurrences[redBanChampionId].bans++;
              } else {
                championOccurrences[redBanChampionId] = { bans: 1, picks: 0 };
              }
            }

            // Process picks
            if (bluePickChampionId) {
              if (championOccurrences[bluePickChampionId]) {
                championOccurrences[bluePickChampionId].picks++;
                if (match.winner === 100) {
                  wins[bluePickChampionId]++;
                }
              } else {
                championOccurrences[bluePickChampionId] = { bans: 0, picks: 1 };
              }
            }

            if (redPickChampionId) {
              if (championOccurrences[redPickChampionId]) {
                championOccurrences[redPickChampionId].picks++;
                if (match.winner === 200) {
                  wins[redPickChampionId]++;
                }
              } else {
                championOccurrences[redPickChampionId] = { bans: 0, picks: 1 };
              }
            }
          }
        });

        Object.keys(championOccurrences).forEach(championId => {
          const picks = championOccurrences[championId]?.picks || 0;
          const bans = championOccurrences[championId]?.bans || 0;
          const totalGames = picks + bans;
          let winRate;
          if (picks === 0) {
            winRate = 0;
          } else {
            winRate = (wins[championId] / picks) * 100;
          }
          const presence = (totalGames / matchData.length) * 100;
          championOccurrences[championId].winRate = winRate;
          championOccurrences[championId].presence = presence;
        });

        return championOccurrences;
      }

      const handleSort = (column) => {
        if (column === sortColumn) {
          // If the same column is clicked, toggle the sorting direction
          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
          // If a different column is clicked, reset the sorting direction and update the column
          setSortColumn(column);
          setSortDirection('asc');
        }
      };

      const handleStartDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
        setStartDate(selectedDate);
      };

      const handleEndDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
        setEndDate(selectedDate);
      };

      const handlePatchChange = selectedOptions => {
        const leagueValues = selectedOptions.map(option => option.value);
        setFilterPatch(leagueValues);
        setSelectedPatch(selectedOptions);
      };

      return (

        <div className='main'>
          <div className='filters'>
          Starting Date
      <input
        type="date"
        value={startDate?.toISOString().split('T')[0]}
        onChange={handleStartDateChange}
      />
      Ending Date
      <input
        type="date"
        value={endDate?.toISOString().split('T')[0]}
        onChange={handleEndDateChange}
      />
            Patch
              <div className='short-chooser'>
                <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
              </div>

          </div>
          <div style={{ color: 'white', backgroundColor: 'black', padding: "10px", width: "100%" }}>

            {championOccurrences && (
            <>
            <h2>Champion Occurrences:</h2>
            <table style={{color: 'black', backgroundColor: 'white'}}>
              <thead>
                <tr>
                  <th onClick={() => handleSort('championName')}>Champion Name</th>
                  <th onClick={() => handleSort('picks')}>Picks</th>
                  <th onClick={() => handleSort('bans')}>Bans</th>
                  <th onClick={() => handleSort('winRate')}>Win Rate</th>
                  <th onClick={() => handleSort('presence')}>Presence</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(championOccurrences)
                  .filter(championId => championId !== '0' && championOccurrences[championId].presence > 0)
                  .sort((a, b) => {
                    const aValue = championOccurrences[a][sortColumn];
                    const bValue = championOccurrences[b][sortColumn];
                    return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
                  })
                  .map(championId => {
                    const { picks, bans, winRate, presence } = championOccurrences[championId];
                    return (
                      <tr key={championId}>
                        <td>
                          <img
                            src={championPhotos[championData[championId]]}
                            alt=''
                            className='small-image'
                          />
                          {championData[championId]}
                        </td>
                        <td>{picks}</td>
                        <td>{bans}</td>
                        <td style={{backgroundColor: getRedGreenCellno0(winRate.toFixed(2), 0, 100) }}>{winRate.toFixed(2)}%</td>
                        <td style={{backgroundColor: getGreenCellColor(presence.toFixed(2), 100) }}>{presence.toFixed(2)}%</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
              </>
            )}
          </div>
          <h1 style={{color: "white"}}>OUR TEAM</h1>
          <ChampionPoolComponnent playerData={filteredPlayerChampionData}/>
          <h1 style={{color: "white"}}>ENEMY TEAM</h1>
          <ChampionPoolComponnent playerData={filteredEnemyChampionData}/>
          <h1 style={{color: "white"}}>This winrate from ENEMY TEAM Perspective</h1>
        </div>
      );
};

export default ScrimsChampionComponent;
