

export const handleROFLClick2 = async (gameId) => {
    const gameIdWithoutNumber = gameId.includes('_') ? gameId.split('_')[0] : gameId;
    try {
        window.open(`https://sanchidataportal.com/api/rofl_download/?game_id=${gameIdWithoutNumber}&apikey=${localStorage.getItem('apikey')}`, "_blank");
    } catch (error) {
        console.error("Error:", error);
        alert("Error downloading ROFL file. Please try again.");
    }
};

export const handleVODClick = (vodLink) => {
    if (vodLink) {
      window.open(vodLink, "_blank"); // Opens the VOD link in a new tab
    } else {
      alert("No VOD link available.");
    }
}